import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { filtersApi } from "../../../api/filtersApi";
import useSearchParamsHandler from "../../../utils/hooks/useSearchParamsHandler";
import { getResultStringToSaveInQuery } from "../../../utils/helpers/searchParamsHelper";
import { getTransformedValuesFromQuery } from "../../../utils/helpers/catalogPageHelper";

const initialState = {
  filters: {},
  selectedFilters: {},
  "min_price": "",
  "max_price": "",
};

const selectedFiltersReducer = (state, action) => {
  switch (action.type) {
  case "set_filters": {
    return action.value;
  }
  case "toggle_selected_filter_status": {
    const { filter } = action;
    const key = filter.main_rewrite_name;

    const isSelected = state.selectedFilters[key]?.some((item) => {
      return item.rewrite_name === filter.rewrite_name;
    });

    let selectedFilters = state.selectedFilters;

    if (isSelected) {
      selectedFilters = {
        ...state.selectedFilters,
        [key]: state.selectedFilters[key].filter((item) => {
          return item.rewrite_name !== filter.rewrite_name;
        }),
      };
    } else {
      if (Array.isArray(selectedFilters[key])) {
        selectedFilters = {
          ...state.selectedFilters,
          [key]: [...state.selectedFilters[key], filter],
        };
      } else {
        selectedFilters = {
          ...state.selectedFilters,
          [key]: [filter],
        };
      }
    }

    return {
      ...state,
      filters: {
        ...state.filters,
        [key]: state.filters[key].map((item) => {
          if (item.rewrite_name !== filter.rewrite_name) {
            return item;
          }
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        })
      },
      selectedFilters,
    };
  }
  case "toggle_single_selected_filter_status": {
    const { item } = action;
    return {
      ...state,
      filters: {
        ...state.filters,
        [item.main_rewrite_name]: state.filters[item.main_rewrite_name].map((el) => {
          if (el.rewrite_name !== item.rewrite_name) {
            return el;
          }
          return {
            ...el,
            isSelected: !el.isSelected,
          };
        }),
      }
    };
  }
  case "set_prise": {
    return {
      ...state,
      [action.priceType]: action.value,
    };
  }
  case "remove_prise": {
    return {
      ...state,
      [action.priceType]: initialState[action.priceType],
    };
  }
  default:
    return state;
  }
};

const AVAILABLE_FILTERS = [
  "brands",
  "badges",
  "product_types",
  "product_properties",
];

const useBSCatalogFilters = () => {
  const [bsCatalog, dispatch] = React.useReducer(selectedFiltersReducer, initialState);
  const [selectedShowMoreKey, setSelectedShowMoreKey] = React.useState("");
  const catalog = useSelector((state) => state.catalog);

  const { searchParams, handleChangeSingleSearchParam } = useSearchParamsHandler();

  const abortController = React.useRef(null);

  const location = useLocation();

  const currentCategory = React.useMemo(() => {
    const currentMatch = location.pathname.match(/kosmetika|parfumeria/g);
    if (!currentMatch) {
      return "";
    }
    return currentMatch[0];
  }, [location.pathname]);

  React.useEffect(() => {
    if (Object.keys(bsCatalog.filters).length > 0) {
      return;
    }

    handleSetFilters({
      filters: catalog.filters,
      selectedFilters: catalog.selectedFilters,
      "min_price": catalog.additionalParams.min_price,
      "max_price": catalog.additionalParams.max_price,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog]);

  const handleSetFilters = React.useCallback((value) => {
    dispatch({
      type: "set_filters",
      value,
    });
  }, []);

  const toggleSelectedFilterStatus = React.useCallback(async (filter) => {
    try {
      const mainKey = filter.main_rewrite_name;

      const resultString = getResultStringToSaveInQuery({
        newItem: filter.rewrite_name,
        currentQuery: searchParams.get(mainKey)
      });

      const formattedParams = Object.fromEntries(searchParams);
      const additionalFilters = getTransformedValuesFromQuery(formattedParams);

      handleChangeSingleSearchParam({
        key: mainKey,
        queryString: resultString
      });

      dispatch({
        type: "toggle_selected_filter_status",
        filter,
      });

      abortController.current = new AbortController();

      const data = await filtersApi.getAllFilters({
        signal: abortController.current.signal,
        filters: AVAILABLE_FILTERS,
        categories: currentCategory || undefined,
        ...additionalFilters,
      });
      // eslint-disable-next-line no-console
      console.log(data);
    } catch(err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory, searchParams]);

  const toggleSelectedFilterStatusFromShowMore = React.useCallback((item) => {
    dispatch({
      type: "toggle_single_selected_filter_status",
      item,
    });
  }, []);

  const setPriceValue = React.useCallback(({ priceType, value }) => {
    dispatch({
      type: "set_prise",
      priceType,
      value,
    });
  }, []);

  const removePriceValue = React.useCallback(({ priceType }) => {
    dispatch({
      type: "set_prise",
      priceType,
    });
  }, []);

  const selectShowMoreItemsKey = React.useCallback((key) => {
    setSelectedShowMoreKey(key);
  }, []);

  // eslint-disable-next-line no-console
  // console.log(bsCatalog);

  return {
    bsCatalog,
    selectedShowMoreKey,
    handleSetFilters,
    toggleSelectedFilterStatus,
    toggleSelectedFilterStatusFromShowMore,
    setPriceValue,
    removePriceValue,
    selectShowMoreItemsKey,
  };
};

export default useBSCatalogFilters;
