import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import cn from "classnames";
import { toast } from "react-toastify";

import { getUserCart } from "../../../../../store/user/cartThunks/cartThunks";
import {
  selectItemsInCartByIds,
  setCheckoutItems,
  setPromocode,
} from "../../../../../store/user/user";
import { userApi } from "../../../../../api/userApi";
import { WAY_TO_GET } from "../../../../../utils/constants";

import PromocodeInput from "../../../../../components/UI/PromocodeInput";
import OrderStringItem from "../../../../../components/UI/OrderStringItem";
import CustomButton from "../../../../../components/CustomButton";
import CheckoutOrderItem from "./components/CheckoutOrderItem";
import CustomScrollbar from "../../../../../components/CustomScrollbar";
import { metric } from "../../../../../utils/yandexMetrics/yandexMetrics";

import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { YANDEX_CREATE_ORDER_GOAL_ID } from "utils/yandexMetrics/yandexMetricsCore";

const MAX_DISPLAY_PRODUCTS = 3;

const UserOrderData = (props) => {
  const { user, currentCity, checkoutData } = useSelector(
    (state) => state.user
  );
  const [isPromocodeSelected, setIsPromocodeSelected] = useState(!!checkoutData.promoCode);
  const [inputValue, setInputValue] = useState(checkoutData.promoCode || "");
  const [isNotValidCode, setIsNotValidCode] = useState("");
  const [showShadow, setShowShadow] = useState({
    top: false,
    bottom: false,
  });
  const [showInfoAboutOrderModal, setShowInfoAboutOrderModal] = useState(false); // eslint-disable-line
  const [isSendingOrder, setIsSendingOrder] = useState(false);

  Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productsMainPrice = useMemo(() => {
    return checkoutData.items.reduce((acc, cur) => {
      acc += (cur.offer.price * cur.count);

      return acc;
    }, 0);

  }, [checkoutData.items]);

  const productsDiscount = useMemo(() => {
    return checkoutData.items.reduce((acc, cur) => {
      acc += cur.offer.price * cur.count -
        ((cur.offer.price_sale || cur.offer.akcia) * cur.count);
      return acc;
    }, 0);
  }, [checkoutData.items]);

  const resultPrice = useMemo(() => {
    const productsSum = checkoutData.items.reduce((acc, cur) => {
      acc += cur.calculated_amount;
      return acc;
    }, 0);

    if (checkoutData.providerInfo?.amount) {
      const totalSum = Number(productsSum) + Number(checkoutData.providerInfo?.amount);
      return totalSum;
    }
    return formatPrice(productsSum);
  }, [checkoutData.items, checkoutData.providerInfo]);

  const isShowScrollbar = useMemo(() => {
    return checkoutData.items.length > MAX_DISPLAY_PRODUCTS;
  }, [checkoutData.items]);

  useEffect(() => {
    setShowShadow((prev) => {
      return {
        ...prev,
        bottom: checkoutData.items.length > MAX_DISPLAY_PRODUCTS,
      };
    });
  }, [checkoutData.items.length]);

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const onSendPromocode = async () => {
    if (!inputValue || !checkoutData.items.length) {
      return;
    }

    try {
      const selectedProductIds = checkoutData.items.map(
        (item) => item.offer.id
      );

      const options = {
        "calculate_orders": selectedProductIds.join(","),
        "use_promo_code": inputValue.trim(),
      };
      const res = await dispatch(getUserCart(options)).unwrap();

      if (!res || !res.meta) {
        return toast("Извините, произошла ошибка при попытке применить промокод. Перезагрузите страницу или попробуйте применить позже.");
      }
      dispatch(setCheckoutItems(selectedProductIds));
      setIsNotValidCode(res.meta?.error);
      if (!res.meta.error) {
        setIsPromocodeSelected(true);
        dispatch(setPromocode(inputValue));
      }
    } catch (err) {
      // TODO
    }
  };

  const onRemovePromocode = async () => {
    try {
      const selectedProductIds = checkoutData.items.reduce((acc, cur) => {
        acc.push(cur.offer.id);
        return acc;
      }, []);

      const options = {
        "calculate_orders": selectedProductIds.join(","),
      };

      const res = await dispatch(getUserCart(options)).unwrap();

      dispatch(setCheckoutItems(selectedProductIds));
      if (!res || !res.meta) {
        return toast("Извините, произошла ошибка при попытке удалить промокод. Перезагрузите страницу или попробуйте применить позже.");
      }

      setIsNotValidCode(res.meta?.error);
      if (!res.meta.error) {
        setIsPromocodeSelected(false);
        setInputValue("");
        dispatch(setPromocode(""));
      }
    } catch (err) {
      // TODO
    }
  };

  const productsPromoDiscount = useMemo(() => {
    return checkoutData.items.reduce((acc, cur) => {
      if (cur.used_promo_code) {
        acc += cur.discount_by_promo_code;
      }

      return acc;
    }, 0);
  }, [checkoutData.items]);

  const onNavigateHandler = () => {
    const ids = checkoutData.items.map((item) => {
      return item.offer.id;
    });

    dispatch(selectItemsInCartByIds(ids));
    navigate("/cart");
  };

  const onShowScrollBarShadows = ({ showTopShadow, showBottomShadow }) => {
    setShowShadow({
      top: showTopShadow,
      bottom: showBottomShadow,
    });
  };

  const addNewOrder = async () => {
    try {
      setIsSendingOrder(true);
      const offers = checkoutData.items.map((item) => {
        return {
          id: item.offer.id,
          count: item.count,
        };
      });

      const deliveryType =
        checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup";

      const recipientFullName = `${checkoutData.recipient.surname ?? ""} ${checkoutData.recipient.name ?? ""}`;

      const userFullName = `${user.surname ?? ""} ${user.name ?? ""} ${user.patronymic ?? ""}`;

      const options = {
        offers,
        recipient: {
          name: recipientFullName || userFullName,
          phone: checkoutData.recipient.phone || user.phone,
          email: checkoutData.recipient.email || user.email,
          address: checkoutData.recipient.address,
        },
        delivery: {
          ...checkoutData.providerInfo,
          type: deliveryType,
        },
      };

      if (props.isAuthorized) {
        options["promo_code"] = checkoutData.promoCode;
        options.basket = true;
      }

      // Different options to clarify what section wasn't selected or filled
      if (!offers.length) {
        toast("Не выбран товар. Пожалуйста, добавьте товар в корзину.");
        return;
      } else if (!options.recipient.name) {
        toast("Пожалуйста укажите имя");
        return;
      } else if (!options.recipient.phone) {
        toast("Пожалуйста укажите номер телефона");
        return;
      } else if (!options.recipient.address) {
        toast("Пожалуйста укажите адрес");
        return;
      }

      const data = await (props.isAuthorized ? userApi.addNewOrderForAuthorizedUsers(options) : userApi.addNewOrderForNonAuthorizedUsers(options));

      if (!Object.keys(data).length) {
        toast("Произошла ошибка оформления заказа. Обратитесь к администрации");
        return;
      }

      if (data.meta.error) {
        toast(data.meta.error);
        return;
      }


      props.setOrderData(data.response);
      props.setIsPay(true);

      metric.newOrderCreateMetric(resultPrice, "RUB");

      // yandex ecommerce 'purchase'
      const coupon = (data.response.promocode && "name" in data.response.promocode) ? data.response.promocode.name : "";
      const purchaseData = {
        ecommerce: {
          currencyCode: "RUB",
          [YandexActionTypeEnum.purchase]: {
            actionField: {
              id: data.response.uuid,
              coupon: coupon,
              // eslint-disable-next-line camelcase
              goal_id: YANDEX_CREATE_ORDER_GOAL_ID
            },
            products: data.response.orderOffers.map(orderItem => {
              const isBrand = orderItem.offer.catalog.brands && !!orderItem.offer.catalog.brands.length;
              return {
                id: orderItem.offer.catalog.id,
                name: orderItem.offer.catalog.name,
                category: orderItem.offer.catalog.category.name,
                brand: isBrand ? orderItem.offer.catalog.brands[0].brand.name : "",
                price:  orderItem.sale_price || orderItem.site_price,
                quantity: orderItem.count,
                variant: `${orderItem.offer.name}, ID: ${orderItem.offer.id}`,
                discount: orderItem.site_price - orderItem.sale_price,
                coupon: coupon,
              };
            })
          }
        }
      };

      await handleYandexEcommerce(purchaseData);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Произошла ошибка при создании заказа", err);
    } finally {
      setIsSendingOrder(false);
    }
  };

  const showDeliveryDate = useMemo(() => {
    const isDaysMin = checkoutData.providerInfo?.daysMin !== undefined && typeof checkoutData.providerInfo.daysMin === "number";
    const isDaysMax = checkoutData.providerInfo?.daysMax !== undefined && typeof checkoutData.providerInfo.daysMax === "number";
    return isDaysMin && isDaysMax;
  }, [checkoutData.providerInfo]);

  const subtitle = useMemo(() => {
    const name = checkoutData.recipient.name || user.name;
    const surname = checkoutData.recipient.surname || user.surname;
    return name && surname ? `${name} ${surname}` : name || "-";
  }, [checkoutData.recipient.name, checkoutData.recipient.surname, user.name, user.surname]);

  return (
    <div className={styles.root}>
      <div className={styles["title-wrapper"]}>
        <h4 className={styles.title}>Ваш заказ</h4>
        <button className={styles["change-order"]} onClick={onNavigateHandler}>
          Изменить заказ
        </button>
      </div>
      <div className={cn(
        styles.wrapper,
        { [styles["products-wrapper--top-shadow"]]: showShadow.top },
        { [styles["products-wrapper--bottom-shadow"]]: showShadow.bottom }
      )}>
        <div
          className={cn(
            styles["products-wrapper"],
          )}
        >
          <CustomScrollbar
            childrenClassName={styles["products"]}
            scrollBarTrackStyles={styles.track}
            isShowScrollbar={isShowScrollbar}
            onShowScrollBarShadows={onShowScrollBarShadows}
          >
            {!checkoutData.items.length ? (
              <h3 className={styles["products__title"]}>
                Нет товаров для оформления
              </h3>
            ) : (
              checkoutData.items.map((item) => {
                return <CheckoutOrderItem key={item.offer.id} item={item} />;
              })
            )}
          </CustomScrollbar>
        </div>
      </div>
      <div className={styles.line} />
      {props.isAuthorized && (
        <PromocodeInput
          onRemovePromocode={onRemovePromocode}
          isPromocodeSelected={isPromocodeSelected}
          inputValue={inputValue}
          isNotValidCode={isNotValidCode}
          setIsNotValidCode={setIsNotValidCode}
          onChange={onChangeHandler}
          onSendPromocode={onSendPromocode}
          buttonClassname={styles.promocode__button}
        />
      )}
      <div className={styles["data-wrapper"]}>
        <div className={styles["user-data"]}>
          <OrderStringItem
            title="Получатель:"
            subtitle={subtitle}
          />
          <OrderStringItem
            title="Номер телефона:"
            subtitle={checkoutData.recipient.phone || user.phone || "-"}
          />
          <OrderStringItem
            title="Населенный пункт:"
            subtitle={checkoutData.orderCity || currentCity || "Не выбрано"}
          />
          <OrderStringItem
            title="Способ получения:"
            subtitle={checkoutData.wayToGet || "Не выбрано"}
          />
          <OrderStringItem
            title="Адрес:"
            subtitle={checkoutData.recipient.address || "Не выбран"}
          />
        </div>
        <div className={styles["price-wrapper"]}>
          <OrderStringItem
            title="Стоимость товаров:"
            subtitle={`${formatPrice(productsMainPrice) || 0} ₽`}
            subtitleClassname={styles.price}
          />
          <OrderStringItem
            title="Скидка:"
            subtitle={`${formatPrice(productsDiscount) || 0} ₽`}
            subtitleClassname={styles.price}
          />
          {props.isAuthorized && (
            <OrderStringItem
              title="Скидка по промокоду:"
              subtitle={`${formatPrice(productsPromoDiscount) || 0} ₽`}
              subtitleClassname={styles.price}
            />
          )}
          <OrderStringItem
            title="Доставка:"
            subtitle={`${formatPrice(checkoutData.providerInfo?.amount) || 0} ₽`}
            subtitleClassname={styles.price}
          />
          <OrderStringItem
            title="Всего к оплате:"
            subtitle={`${resultPrice || 0} ₽`}
            subtitleClassname={styles.price}
          />
        </div>
      </div>
      <p className={styles.date}>
        Срок доставки:
        {showDeliveryDate ?
          <span> {checkoutData.providerInfo.daysMin} - {checkoutData.providerInfo.daysMax} д.</span> :
          <span> доступно после выбора адреса доставки</span>
        }
      </p>
      <span className={styles.warning}>*с момента готовности заказа</span>
      <div className={styles.offer}>
        <p className={styles.offer__description}>
          <span>Нажимая на кнопку &quot;оформить заказ&quot;, вы подтверждаете, что ознакомились и согласны с условиями</span>
          <Link to="/info/publichnaya-oferta" className={styles.offer__link}>
            публичной оферты
          </Link>
          <span>PARFUMART.RU</span>
        </p>
      </div>

      <CustomButton
        title="Оформить заказ"
        className={styles["checkout-button"]}
        onClick={addNewOrder}
        isDisabled={isSendingOrder}
      />
    </div>
  );
};

const propTypes = {
  setIsPay: PropTypes.func,
  setOrderData: PropTypes.func,
  isAuthorized: PropTypes.bool.isRequired,
};

UserOrderData.propTypes = propTypes;

export default UserOrderData;
