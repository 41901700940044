import { useState } from "react";
import { useFormik } from "formik";
import { nanoid } from "@reduxjs/toolkit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { signInUser } from "../../store/user/userThunks/userThunks";
import { getUserCart } from "../../store/user/cartThunks/cartThunks";
import { setTokens } from "../../utils/localStorage";

import { signInSchema } from "../../schemas/signInSchema";
import CustomAuthInput from "../../components/CustomAuthInput/CustomAuthInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

import chechMarkIcon from "../../assets/icons/png/chech_mark-icon.png";

import styles from "./styles.module.scss";
import { removeCart } from "../../store/user/user";
import { getErrorMessage } from "../../utils/helpers/errorHelper";
import { SIGN_IN_MESSAGE_HINT } from "../../utils/constants/messageHint";
import NotificationHint from "../../components/NotificationHint/NotificationHint";

const SIGN_IN_VALUES = [
  {
    id: nanoid(),
    title: "+ 7(_ _ _) _ _ _-_ _-_ _",
    name: "login",
    inputType: "tel",
  },
  {
    id: nanoid(),
    title: "Пароль",
    name: "password",
    inputType: "password",
  },
];

const SignIn = () => {
  const [showErrorsOnSubmit, setShowErrorsOnSubmit] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(true);
  const [isSubmitError, setIsSubmitError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: signInSchema(),
    onSubmit: async () => {
      try {
        const numberLogin = formik.values.login.replace(/[^\d]/g, "");
        const userData = await dispatch(
          signInUser({
            username: numberLogin,
            password: formik.values.password,
          })
        ).unwrap();

        if (!userData?.user) {
          return;
        }

        if (showCheckBox) {
          setTokens({
            token: userData.token,
            refreshToken: userData.refreshToken,
          });
        } else {
          sessionStorage.setItem("accessToken", userData.token);
        }
        dispatch(removeCart());
        dispatch(getUserCart({}));
        navigate(location.state?.from?.pathname || "/", { replace: true });
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
      }
    },
  });

  const onClickShowCheckBox = () => {
    setShowCheckBox(!showCheckBox);
  };

  const onSubmitHandler = () => {
    setShowErrorsOnSubmit(true);
    setIsSubmitError(true);
    formik.handleSubmit();
  };

  const handleChangeAndResetErrors = (e) => {
    setShowErrorsOnSubmit(false);
    setIsSubmitError(false);
    formik.handleChange(e);
  };

  return (
    <main className={styles.root}>
      <h2 className={styles.title}>Авторизация</h2>
      <NotificationHint message={SIGN_IN_MESSAGE_HINT} />
      <form method="post" onSubmit={onSubmitHandler} className={styles.form}>
        {SIGN_IN_VALUES.map((item) => {
          return (
            <CustomAuthInput
              key={item.id}
              name={item.name}
              inputType={item.inputType}
              placeholder={item.title}
              value={formik.values[`${item.name}`]}
              onChange={handleChangeAndResetErrors}
              errorValue={formik.errors[`${item.name}`]}
              isSubmitting={isSubmitError}
              showErrorsOnSubmit={showErrorsOnSubmit}
            />
          );
        })}
      </form>
      <button
        className={styles["remember-button"]}
        onClick={onClickShowCheckBox}
      >
        <div className={styles["remember-button__checkbox"]}>
          {showCheckBox && <img src={chechMarkIcon} alt="chech_mark-icon" />}
        </div>
        <span className={styles["remember-button__title"]}>Запомнить меня</span>
      </button>
      <Link to="restore/by-email" className={styles["forget-button"]}>
        Забыли свой пароль?
      </Link>
      <CustomButton
        title="Войти"
        onClick={onSubmitHandler}
        type="submit"
        className={styles["singin-button"]}
      />
      <div className={styles["auth-content"]}>
        <span className={styles["auth-content__title"]}>
          Нет учётной записи?
        </span>
        <Link to="/sign-up" className={styles["auth-content__subtitle"]}>
          Зарегистрируйтесь сейчас
        </Link>
      </div>
      <SocialMedia isLogin />
    </main>
  );
};

export default SignIn;
