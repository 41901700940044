import { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import CustomButton from "../../../../../components/CustomButton";
import PriceFilter from "../PriceFilter";
import FilterButton from "./FilterButton";
import { useLocation } from "react-router-dom";
import { defaultFilterOrder, kosmetikaFilterOrder, parfumeriaFilterOrder } from "../../../../../utils/constants/catalogFilter";

import cn from "classnames";
import styles from "./styles.module.scss";

const MainFilter = (props) => {
  const location = useLocation();
  const { deviceType } = useSelector((state) => state.user);

  const filterOrder = useMemo(() => {
    if (location.pathname.includes("parfumeria")) {
      return parfumeriaFilterOrder;
    }
    if (location.pathname.includes("kosmetika")) {
      return kosmetikaFilterOrder;
    }
    return defaultFilterOrder;
  }, [location.pathname]);

  const sortedFilters = useMemo(() => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { prices, ...restFilters } = props.filters;
    const filtered = Object.entries(restFilters)
      .filter(([key, value]) => value.length > 0 && filterOrder.includes(key))
      .sort((a, b) => filterOrder.indexOf(a[0]) - filterOrder.indexOf(b[0]))
      .map(([, value]) => value);
    return filtered;
  }, [props.filters, filterOrder]);

  return (
    <ul className={cn(styles.root, props.containerStyles)}>
      {!deviceType.isMobile && (
        <>
          <h3 className={styles.title}>Выбранные фильтры</h3>
          <CustomButton
            title="Сбросить фильтры"
            className={styles["reset-filters"]}
            isWhite
            onClick={props.handleResetAllFilters}
          />
        </>
      )}
      {sortedFilters.map((item) => {
        return (
          <FilterButton
            key={item[0].main_name}
            mainTitle={item[0]?.main_name}
            filters={item}
            onClickSelectFilter={props.onClickSelectFilter}
            showMoreFilters={props.showMoreFilters}
          />
        );
      })}
      <PriceFilter
        minPrice={props.minPrice}
        maxPrice={props.maxPrice}
        setPriceOption={props.setPriceOption}
        removePriceOption={props.removePriceOption}
      />
    </ul>
  );
};

const propTypes = {
  filters: PropTypes.object.isRequired,
  minPrice: PropTypes.string.isRequired,
  maxPrice: PropTypes.string.isRequired,
  onClickSelectFilter: PropTypes.func.isRequired,
  setPriceOption: PropTypes.func.isRequired,
  removePriceOption: PropTypes.func.isRequired,
  handleResetAllFilters: PropTypes.func,
  containerStyles: PropTypes.string,
  showMoreFilters: PropTypes.func,
};

MainFilter.propTypes = propTypes;

export default MainFilter;