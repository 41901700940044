import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import useDidUpdate from "../../../../../../utils/hooks/useDidUpdate";
import useShowShadow from "../../../../../../utils/hooks/useShowShadow";
import { setCatalogState, toggleSearchTrigger } from "../../../../../../store/catalog";
import useSearchParamsHandler from "../../../../../../utils/hooks/useSearchParamsHandler";

import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import FilterButtonItem from "../FilterButton/FilterButtonItem";
import SearchInput from "../FilterButton/SearchInput";
import CustomButton from "../../../../../../components/CustomButton";

import cn from "classnames";
import styles from "./styles.module.scss";

const ShowMoreFilterItemsListBS = (props) => {
  const catalog = useSelector((state) => state.catalog);
  const [currentFilters, setCurrentFilters] = React.useState(props.filters);
  const [isError, setIsError] = React.useState(false);

  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow();
  const { searchParams, handleChangeSingleSearchParam } = useSearchParamsHandler();

  const listRef = React.useRef(null);

  const catalogPrevState = React.useRef({});
  const shouldReturnPrevState = React.useRef(false);

  const dispatch = useDispatch();

  useDidUpdate(() => {
    setCurrentFilters(props.filters);
  }, [props.filters]);

  useDidUpdate(() => {
    handleEnteredBS();
  }, [currentFilters.length]);

  const changeCurrentFilters = React.useCallback(({ filters, isError }) => {
    setCurrentFilters(filters);
    setIsError(isError);
  }, []);

  const handleEnteredBS = () => {
    changeShowShadow({
      top: false,
      bottom: listRef.current?.scrollHeight > listRef.current?.offsetHeight,
    });

    catalogPrevState.current = {
      catalog,
      params: Object.fromEntries(searchParams),
    };
  };

  const handleExitedBS = () => {
    if (shouldReturnPrevState.current) {
      dispatch(setCatalogState(catalogPrevState.current.catalog));
      handleChangeSingleSearchParam({
        key: props.selectedShowMoreKey,
        queryString: catalogPrevState.current.params[props.selectedShowMoreKey] ?? ""
      });
    }
    shouldReturnPrevState.current = false;
    catalogPrevState.current = {};
  };

  const handleSubmitFilters = React.useCallback(() => {
    props.onClose();
    dispatch(toggleSearchTrigger());
    shouldReturnPrevState.current = false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = React.useCallback(() => {
    props.onClose();
    shouldReturnPrevState.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BottomSheet
      isOpen={props.isOpen}
      shouldBeOnFullScreen
      onClose={handleClose}
      onEntered={handleEnteredBS}
      onExited={handleExitedBS}
    >
      <SearchInput
        filters={props.filters}
        changeCurrentFilters={changeCurrentFilters}
        containerStyles={styles["search-input"]}
      />
      <div
        className={cn(
          styles["list-wrapper"],
          { [styles["list-wrapper--top-shadow"]]: showShadow.top },
          { [styles["list-wrapper--bottom-shadow"]]: showShadow.bottom }
        )}
      >
        <ul
          className={styles.list}
          ref={listRef}
          onScroll={onScrollHandler}
        >
          {isError ? (
            <p className={styles.error}>Ничего не найдено</p>
          ) : (
            currentFilters.map((item) => {
              return (
                <FilterButtonItem
                  key={item.rewrite_name}
                  item={item}
                  onClickSelectFilter={() => props.onSelectFilter(item, true)}
                />
              );
            })
          )}
        </ul>
      </div>
      <CustomButton
        title="Применить"
        className={styles["accept-filters"]}
        onClick={handleSubmitFilters}
      />
    </BottomSheet>
  );
};

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedShowMoreKey: PropTypes.string.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ShowMoreFilterItemsListBS.propTypes = propTypes;

export default ShowMoreFilterItemsListBS;
