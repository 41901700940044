import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  addUserFavoriteProduct,
  removeUserFavoriteProduct,
} from "../../store/user/userThunks/userThunks";
import { getProductPrices } from "../../utils/getProductPrices";

import CustomButton from "../CustomButton";
import CustomLink from "../CustomLink";
import ProductCardLabel from "../ProductCardLabel";
import ProductCardOffersModal from "../UI/ProductCardOffersModal";

import { ProductType } from "../../types/productTypes";

import Cosmetics from "../../assets/icons/svg/cosmetics.svg";
import heartEmptyIcon from "../../assets/icons/png/heart_empty-icon.png";
import heartFoolIcon from "../../assets/icons/png/heart_fool-icon.png";
import newIcon from "../../assets/icons/sale-icons/new.svg";
import saleIcon from "../../assets/icons/sale-icons/sale.svg";
import fireIcon from "../../assets/icons/sale-icons/trace.svg";
import noImgProductLogo from "../../assets/images/no-photo.png";

import cn from "classnames";
import { ModalRedirect } from "../ModalRedirect/ModalRedirect";
import styles from "./styles.module.scss";
import getSortedOffersByPriceAndVolume from "../../utils/getSortedOffersByPriceAndVolume";
import useSearchParamsHandler from "../../utils/hooks/useSearchParamsHandler";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";



const ProductCard = ({ product, onToggleIsOffersModalOpenedStatus }) => {
  const { user } = useSelector((state) => state.user);
  const [showSelectOfferModal, setShowSelectOfferModal] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [isOpenRedirectModalCart, setIsOpenRedirectModalCart] = useState(false);
  const modalRedirectCartRef = useRef(null);
  const navigate = useNavigate();

  const modalRef = useRef(null);

  const isProductInFavourite = useMemo(() => {
    const idx = user.favouriteProducts.findIndex((item) => item.id === product.id);
    return idx !== -1;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.favouriteProducts]);

  const [isFavorite, setIsFavorite] = useState(isProductInFavourite);
  const { searchParams } = useSearchParamsHandler();
  const { type } = Object.fromEntries(searchParams);
  const [currentOffer, setCurrentOffer] = useState({
    id: 0,
    description: "",
    promotionPrice: null,
    price: null,
    percent: null,
  });

  const dispatch = useDispatch();
  const location = useLocation();

  const isNewProduct = useMemo(() => {
    return product.offers.some((item) => !!item.new_label);
  }, [product.offers]);

  const isBestsellerProduct = useMemo(() => {
    return product.offers.some((item) => !!item.bestseller);
  }, [product.offers]);

  const isSaleProduct = useMemo(() => {
    return product.offers.some((item) => !!item.price_sale);
  }, [product.offers]);

  const productMinDescription = useMemo(() => {
    const productItemWithVolume = product.offers.find((item) => {
      if (!item.offerVolumes) return "";
      return item.offerVolumes.find((elem) => {
        return elem.value_type;
      });
    });

    if (!productItemWithVolume) return "";

    const productVolume = productItemWithVolume.offerVolumes[0].value_type;

    const description = product.offers
      .sort((a, b) => a.name.length - b.name.length)[0]
      .name.split(`${productVolume} `)[1];

    if (!description) return "";

    return description[0].toUpperCase() + description.slice(1);
  }, [product.offers]);

  const productPrice = useMemo(() => {
    return getProductPrices(product.offers);
  }, [product.offers]);

  const productAmount = useMemo(() => {
    const productItemWithVolume = product.offers.find((item) => {
      if (!item.offerVolumes) return;
      return item.offerVolumes.find((elem) => {
        return elem.value_type;
      });
    });

    if (!productItemWithVolume) {
      return {
        min: "",
        max: "",
        productVolume: "",
      };
    }

    const productVolume = productItemWithVolume.offerVolumes[0].value_type ?? "";

    if (product.offers.length === 1) {
      return {
        min: product.offers[0].offerVolumes[0].value ?? "",
        max: "",
        productVolume,
      };
    }

    const offersCopy = [...product.offers];

    const productMinValueAmount = offersCopy.sort((a, b) => {
      return a.offerVolumes[0]?.value - b.offerVolumes[0]?.value;
    })?.[0].offerVolumes?.[0]?.value;

    const productMaxValueAmount = offersCopy.sort((a, b) => {
      return b.offerVolumes[0]?.value - a.offerVolumes[0]?.value;
    })?.[0]?.offerVolumes?.[0]?.value;

    if (productMinValueAmount === productMaxValueAmount) {
      return {
        min: productMinValueAmount ?? "",
        max: "",
        productVolume,
      };
    }

    return {
      min: productMinValueAmount ?? "",
      max: productMaxValueAmount ?? "",
      productVolume,
    };
  }, [product.offers]);

  const brandTitle = useMemo(() => {
    const productWithBrand = product.brands.find((item) => item.brand.name);

    if (!productWithBrand?.brand?.name) return "";

    return productWithBrand.brand.name;
  }, [product.brands]);

  const salePercent = useMemo(() => {
    const availableOffers = product.offers.filter(offer => offer.available);
    const allSame = availableOffers.every(offer => {
      return offer.percent === availableOffers[0].percent;
    });
    if (allSame) {
      return availableOffers[0].percent || 0;
    } else {
      const maxSalePercent = availableOffers.reduce((max, item) => {
        return item.percent && item.percent > max ? item.percent : max;
      }, 0);
      return maxSalePercent;
    }
  }, [product.offers]);

  const sortedOffers = useMemo(() => {
    if (!product) return [];
    return getSortedOffersByPriceAndVolume(product.offers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.offers]);

  useEffect(() => {

    const filteredOffer = sortedOffers.find(offer => offer.id === Number(type)) || sortedOffers[0];

    setCurrentOffer({
      id: filteredOffer?.id || null,
      description: filteredOffer?.name ?? "",
      promotionPrice: filteredOffer?.price_sale ?? filteredOffer?.akcia ?? null,
      price: filteredOffer?.price ?? null,
      percent: filteredOffer?.percent ?? null,
    });

  }, [sortedOffers, type]);

  const productUrl = useMemo(() => {
    let url = `/product/${product.id}-${product.product_rewrite_name}`;
    if (currentOffer) {
      url += `?type=${currentOffer.id}`;
    }
    return url;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, product.id, product.product_rewrite_name, currentOffer]);

  const onToggleFavoriteStatus = async (e) => {
    e.stopPropagation();
    try {
      setIsFavorite(!isFavorite);
      if (!isFavorite) {
        dispatch(addUserFavoriteProduct(product.id));
      } else {
        dispatch(removeUserFavoriteProduct(product.id));
      }
    } catch (err) {
      // TODO
    }
  };

  const onOpenSelectOfferModal = (e) => {
    e.stopPropagation();

    setShowSelectOfferModal(true);
    onToggleIsOffersModalOpenedStatus?.(true);
  };

  const onCloseSelectOfferModal = () => {
    setShowSelectOfferModal(false);
    onToggleIsOffersModalOpenedStatus?.(false);
  };

  const handleSelectCard = async (e) => {
    e.stopPropagation();
    if (process.env.REACT_APP_REDIRECT_PRODUCT === "true") return setIsOpenRedirectModalCart(true);
    // yandex ecommerce 'click'
    const ecommerceClickData = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.click]: {
          products: [
            {
              id: product.id,
              name: product.name,
              category: product.category.name,
              brand: (product.brands && !!product.brands.length) ? product.brands[0].brand.name : "",
            }
          ]
        }
      }
    };
    await handleYandexEcommerce(ecommerceClickData);

    navigate(productUrl);
  };

  const onCloseModal = (e) => {
    if (e) e.stopPropagation();
    setIsOpenRedirectModalCart(false);
  };

  const newParfumartUrlProduct = `${process.env.REACT_APP_REDIRECT_PRODUCT_URL}/${product.id}-${product.product_rewrite_name}`;

  const LinkToButton = useCallback(() => {
    if (process.env.REACT_APP_REDIRECT_PRODUCT === "true") {
      return (
        <button className={styles.linkInfo} onClick={() => setIsOpenRedirectModalCart(true)}>
          Подробнее о товаре
        </button>
      );
    }
    return <CustomLink title="Подробнее о товаре" path={productUrl} isPrimary />;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noImageLink = useMemo(() => {
    return product?.category.rewrite_name === "kosmetika"
      ?
      "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg" :
      "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [product?.category]);

  const mainSrc = product?.image?.link ? product?.image?.link : noImageLink;

  return (
    <>
      <div
        className={styles.card}
        onClick={handleSelectCard}
        itemType="https://schema.org/Product"
        itemScope
      >
        <div className={styles.card__labels}>
          {/* {isStockProduct && (
            <ProductCardLabel title="Акция" imgSrc={mouthpieceIcon} imgAlt="promotion-icon" />
          )} */}
          {isNewProduct && <ProductCardLabel iconStyle={styles.newIcon} title="Новинка" imgSrc={newIcon} imgAlt="new-icon" />}
          {isBestsellerProduct && (
            <ProductCardLabel title="Бестселлер" iconStyle={styles.fireIcon} imgSrc={fireIcon} imgAlt="best_seller-icon" />
          )}
        </div>
        <span className={styles["card_img-wrapper"]}>
          {!loadImg &&
            <img
              src={Cosmetics}
              className={styles["card_img-svg"]}
            />
          }
          <img
            itemProp="image"
            src={mainSrc}
            alt={product.product_rewrite_name}
            className={cn(styles.card_img, {
              [styles["card_img-show"]]: loadImg,
            })}
            onLoad={() => setLoadImg(true)}
          />
          {!!user?.phone && (
            <div onClick={onToggleFavoriteStatus} className={styles["card__heart_icon-wrapper"]}>
              <img
                src={!isFavorite ? heartEmptyIcon : heartFoolIcon}
                alt="heart-icon"
                className={styles.header__additional__item_icon}
              />
            </div>
          )}
        </span>
        <div className={styles.card__labels}>
          {isSaleProduct ? (
            <ProductCardLabel
              title="Распродажа"
              iconStyle={styles.saleIcon}
              imgSrc={saleIcon}
              imgAlt="sale-icon"
              className={styles["sale-label"]}
            />
          ) : (
            <div className={styles["card__empty-label-row"]} />
          )}
        </div>
        <span className={styles["card_brand-title"]} itemProp="brand">{brandTitle}</span>
        <span className={styles.card_title} itemProp="name" >{product.name}</span>
        <div className={styles.card_description} itemProp="description">{productMinDescription}</div>
        <div
          className={cn(styles.card__price, {
            [styles["card__price-min-content"]]: !productPrice.max,
          })}
          itemProp="offers"
          itemType="https://schema.org/Offers"
          itemScope
        >
          <meta itemProp="priceCurrency" content="RUB" />

          {!productPrice.max ? (
            <>
              <span >
                <span
                  itemProp="price"
                  content={productPrice.min}
                >
                  {productPrice.min}
                </span>
                <span> ₽ </span>
              </span>
            </>
          ) : (
            <>
              <span >
                <span> От </span>
                <span
                  itemProp="lowPrice"
                  content={productPrice.min}
                >
                  {productPrice.min}
                </span>
                <span> ₽ </span>
              </span>

              <span >
                <span> До </span>
                <span
                  itemProp="highPrice"
                  content={productPrice.max}
                >
                  {productPrice.max}
                </span>
                <span> ₽ </span>
              </span>
            </>
          )}
        </div>
        <div>
          {salePercent && (
            <ProductCardLabel
              title={`${salePercent}%`}
              imgAlt="sale-icon"
              className={styles["sale-percent"]}
              shouldShowTitle
            />
          )}
        </div>

        {!!productAmount.min && (
          <span
            className={cn(styles.card_amount, {
              [styles["card_amount-min-content"]]: !productAmount.max,
            })}
          >
            {!productAmount.max ? (
              <span>{`${productAmount.min} ${productAmount.productVolume}.`}</span>
            ) : (
              <>
                <span>{`От ${productAmount.min} ${productAmount.productVolume}`}</span>
                <span>{`До ${productAmount.max} ${productAmount.productVolume}`}</span>
              </>
            )}
          </span>
        )}
        <div className={styles.card__buttons}>
          <CustomButton title="Добавить в корзину" onClick={onOpenSelectOfferModal} />
          <LinkToButton />
        </div>

        {showSelectOfferModal && (
          <ProductCardOffersModal
            logoSrc={product.reducedImage?.link ?? noImgProductLogo}
            title={product.name}
            productDescription={productMinDescription}
            offers={product.offers}
            productUrl={productUrl}
            modalRef={modalRef}
            onClose={onCloseSelectOfferModal}
            setIsOpenRedirectModalCart={setIsOpenRedirectModalCart}
            productItem={product}
          />
        )}
      </div>

      <ModalRedirect
        onClose={onCloseModal}
        url={newParfumartUrlProduct}
        isOpen={isOpenRedirectModalCart}
        modalRef={modalRedirectCartRef}
        textForRedirect={"оформления заказа"}
      />
    </>
  );
};

ProductCard.propTypes = ProductType;

export default ProductCard;
