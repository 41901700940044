import PropTypes from "prop-types";

import noImgProductLogo from "../../../../../../assets/images/no-photo.png";

import cn from "classnames";
import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";

const OrderItem = (props) => {
  return(
    <div className={cn(styles.root, props.containerStyles)}>
      <img
        src={props.item.offer?.catalog.image.link ?? noImgProductLogo}
        alt={props.item.offer?.name}
        className={styles.logo}
      />
      <div className={styles.content}>
        <span className={styles.content__title}>
          {props.item.offer?.catalog?.name}, {props.item.offer?.name}
        </span>
        {props.item.offer?.catalog.vendorCode && (<span className={styles["content__vendor-code"]}>
          {`Артикул: ${props.item.offer?.catalog.vendorCode}`}
        </span>)}
        <span className={styles["content__price-wrapper"]}>
          <span className={styles["content__main-price"]}>
            {props.item.sale_price !== props.item.site_price ? `${formatPrice(props.item.site_price)} ₽` : ""}
          </span>
          <span className={styles["content__sale-price"]}>
            {`${formatPrice(props.item.sale_price)} ₽`}
          </span>
          <span className={styles["content__item-amount"]}>
            {`x ${props.amount}`}
          </span>
        </span>
      </div>
    </div>
  );
};

const propTypes = {
  item: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  containerStyles: PropTypes.string,
};

OrderItem.propTypes = propTypes;

export default OrderItem;