import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import questionMark from "../../../../../../../../assets/icons/svg/question-mark.svg";
import {
  addUserFavoriteProduct,
  removeUserFavoriteProduct,
} from "../../../../../../../../store/user/userThunks/userThunks";

import heartFoolIcon from "../../../../../../../../assets/icons/png/heart_fool-icon.png";
import heartEmptyIcon from "../../../../../../../../assets/icons/png/heart_empty-icon.png";
import deleteIcon from "../../../../../../../../assets/icons/png/delete-icon.png";
// import deleteIcon from "../../../../../../../../assets/icons/svg/delete-icon.svg";

import ActionButton from "../ActionButton";

import cn from "classnames";
import styles from "./styles.module.scss";
import ModalMarkDownInfo from "../../../../../../../../components/UI/ModalMarkDownInfo/ModalMarkDownInfo";
import BottomSheet from "../../../../../../../../components/UI/Bottomsheet/Bottomsheet";
import ContentMarkDownInfo from "../../../../../../../../components/UI/ContentMarkDownInfo/ContentMarkDownInfo";

const CartItemProductActions = (props) => {
  const { user, deviceType } = useSelector((state) => state.user);
  const [isModalMarkDown, setIsModalMarkDown] = useState(false);
  const refModalMarkDown = useRef(null);

  const dispatch = useDispatch();

  const isFavoriteProduct = useMemo(() => {
    const favoriteItemIndex = user.favouriteProducts.findIndex((item) => {
      return item.id === props.offerId;
    });

    return favoriteItemIndex >= 0;
  }, [user.favouriteProducts, props.offerId]);

  const onClickSetFavorite = () => {
    try {
      if (!isFavoriteProduct) {
        dispatch(addUserFavoriteProduct(props.offerId));
        return;
      }
      dispatch(removeUserFavoriteProduct(props.offerId));
    } catch (err) {
      // TODO
    }
  };

  const onDeleteProduct = (id) => {
    props.setIdDelProduct(id);
    props.setIsModal(true);
  };

  const findMarkdown = useCallback(() => {
    if (!props.offer.offerGoodsTypes[0]) return false;
    const isMarkdown = props.offer.offerGoodsTypes.filter(element => element.value === "уценка");

    return !!isMarkdown[0];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMarkDownInfo = useMemo(() => {
    isModalMarkDown && !deviceType.isTablet && !deviceType.isMobile;
  }, [isModalMarkDown, deviceType]);

  return (
    <div className={cn(styles.actions, props.containerStyles)}>
      <ActionButton
        title={isFavoriteProduct ? "В избранном" : "В избранное"}
        isMobile={deviceType.isMobile}
        onClick={onClickSetFavorite}
        containerStyles={styles["actions--favorite"]}
      >
        <img
          src={isFavoriteProduct ? heartFoolIcon : heartEmptyIcon}
          alt="favorite-icon"
          className={cn(styles.actions__image, styles["actions__image--heart"])}
        />
      </ActionButton>
      {props.children}
      <ActionButton
        title="Удалить"
        isMobile={deviceType.isMobile}
        onClick={() => onDeleteProduct(props.offerId)}
        containerStyles={styles.actions__button}
      >
        <img
          src={deleteIcon}
          alt="delete-icon"
          className={styles.actions__image}
        />
      </ActionButton>
      {findMarkdown() &&
        <ActionButton
          title="Уценка"
          isMobile={deviceType.isMobile}
          onClick={() => setIsModalMarkDown(true)}
          containerStyles={styles.actions__button}
        >
          <img
            src={questionMark}
            alt="delete-icon"
            className={styles.actions__image}
          />
        </ActionButton>
      }
      {isMarkDownInfo && <ModalMarkDownInfo onClose={() => setIsModalMarkDown(false)} modalRef={refModalMarkDown} />}
      <BottomSheet
        isOpen={isModalMarkDown && (deviceType.isTablet || deviceType.isMobile)}
        onClose={() => setIsModalMarkDown(false)}
      >
        <ContentMarkDownInfo onClose={() => setIsModalMarkDown(false)} />
      </BottomSheet>
    </div >
  );
};

const propTypes = {
  offerId: PropTypes.number.isRequired,
  containerStyles: PropTypes.string,
  children: PropTypes.node,
  offer: PropTypes.object,
  setIsModal: PropTypes.func,
  setIdDelProduct: PropTypes.func,
};

CartItemProductActions.propTypes = propTypes;

export default CartItemProductActions;
