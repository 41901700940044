import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { selectItemInCart } from "../../../../../../store/user/user";

import Checkbox from "../../../../../../components/UI/Checkbox";
import CartItemPriceValue from "./components/CartItemPriceValue";
import CartItemProductActions from "./components/CartItemProductActions";
import SelectProductsAmountButtons from "./components/SelectProductsAmountButtons";

import heartEmptyIcon from "../../../../../../assets/icons/png/heart_empty-icon.png";
import heartFoolIcon from "../../../../../../assets/icons/png/heart_fool-icon.png";

import cn from "classnames";
import styles from "./styles.module.scss";
import { Link, useLocation } from "react-router-dom";
import { addUserFavoriteProduct, removeUserFavoriteProduct } from "../../../../../../store/user/userThunks/userThunks";
import { formatPrice } from "utils/helpers/formatedPrice";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";

const CartProductItem = props => {
  const { deviceType, user } = useSelector(state => state.user);
  const location = useLocation();
  const dispatch = useDispatch();

  const isProductInFavourite = useMemo(() => {
    const { product } = props;
    const idx = user.favouriteProducts.findIndex((item) => item.id === product.offer.catalog.id);
    return idx !== -1;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.favouriteProducts]);

  const [isFavorite, setIsFavorite] = useState(isProductInFavourite);

  const productType = useMemo(() => {
    const productTypes = props.product?.offer?.offerProductTypes || [];
    const volumes = props.product?.offer?.offerVolumes || [];
    const colours = props.product?.offer?.offerColours || [];

    if (productTypes.length === 0) return "";

    const productTypesString = productTypes.map(pt => pt.value).join(", ");
    let volumesString = "";
    let coloursString = "";

    if (volumes.length > 0) {
      if (volumes.length === 1) {
        volumesString = `${volumes[0].value} ${volumes[0].value_type}`;
      } else {
        volumesString = `(${volumes.map(v => `${v.value} ${v.value_type}`).join(", ")})`;
      }
    }

    if (colours.length > 0) {
      coloursString = colours.map(c => c.value).join(", ");
    }

    let resultString = productTypesString;
    if (volumesString) {
      resultString += `, ${volumesString}`;
    }
    if (coloursString) {
      resultString += `, ${coloursString}`;
    }

    return resultString;
  }, [props.product?.offer]);

  const productCatalog = useMemo(() => {
    return props.product?.offer?.catalog || {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, props?.product]);

  const productUrl = useMemo(() => {
    let url = `/product/${productCatalog.id}-${productCatalog.product_rewrite_name}`;
    if (props.product.offer.id) {
      url += `?type=${props.product.offer.id}`;
    }
    return url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, productCatalog?.id, productCatalog?.product_rewrite_name, props.product.offer.id]);

  const selectItem = () => {
    if (!props.product?.offer?.available) {
      return;
    }
    dispatch(selectItemInCart(props.product.offer.id));
  };

  const noImageLink = useMemo(() => {
    return props.product?.category?.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [props.product?.category]);

  const minimalPrice = useMemo(() => {
    const { product } = props;
    if (product && product.offer) {
      return product.offer.price_sale || product.offer.akcia || product.offer.price;
    }
    return "";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.product]);

  const onToggleFavoriteStatus = async (e) => {
    const { product } = props;
    e.stopPropagation();
    try {
      setIsFavorite(!isFavorite);
      if (!isFavorite) {
        dispatch(addUserFavoriteProduct(product.offer.catalog.id));
      } else {
        dispatch(removeUserFavoriteProduct(product.offer.catalog.id));
      }
    } catch (err) {
      // TODO
    }
  };

  const handleEcommerceAction = async () => {
    // yandex ecommerce 'click'
    const productFromCatalog = props.product.offer.catalog;
    const ecommerceClickData = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.click]: {
          products: [
            {
              id: productFromCatalog.id,
              name: productFromCatalog.name,
              category: productFromCatalog.category.name,
              brand: (productFromCatalog.brands && !!productFromCatalog.brands.length) ? productFromCatalog.brands[0].brand.name : "",
            }
          ]
        }
      }
    };
    await handleYandexEcommerce(ecommerceClickData);
  };

  return (
    <div
      className={cn(styles.root, {
        [styles["root--not-available"]]: !props.product?.offer?.available,
      })}
      data-offer_id={props.product?.offer?.id}
      data-group_id={props.product?.offer?.catalog?.id}
    >
      <Checkbox
        isSelected={props.product?.isSelected}
        setIsSelected={selectItem}
      />
      <div className={styles.imageWrapper}>
        <Link to={productUrl} onClick={handleEcommerceAction}>
          <img
            src={props.product?.offer?.catalog?.image?.link ?? noImageLink}
            alt={props.product?.offer?.catalog?.product_rewrite_name}
            className={cn(styles.image, {
              [styles["image--not-available"]]: !props.product?.offer?.available,
            })}
          />
        </Link>
        <div className={styles.heart__wrapper}>
          <div onClick={onToggleFavoriteStatus} className={styles.heart__action}>
            <img
              src={!isFavorite ? heartEmptyIcon : heartFoolIcon}
              alt="heart-icon"
              className={styles.heart__item_icon}
            />
          </div>
        </div>
      </div>
      <div className={styles.data}>
        {deviceType.isMobile && (
          <CartItemPriceValue
            mainPrice={props.product?.offer?.price}
            productAmount={props.product?.count}
            calculatedPrice={props.product?.calculated_price}
            discountPrice={props.product?.offer?.akcia}
            salePrice={props.product?.offer?.price_sale}
          />
        )}
        <Link to={productUrl}>
          <h3 className={styles.data__title}>
            {props.product?.offer?.catalog?.name}
          </h3>
        </Link>
        <div className={styles["data__inner-wrapper"]}>
          <p className={styles["data__product-type"]}>{productType}</p>
          {!deviceType.isMobile && (
            <div className={styles["data__price-container"]}>
              <CartItemPriceValue
                mainPrice={props.product?.offer?.price}
                productAmount={props.product?.count}
                calculatedPrice={props.product?.calculated_price}
                discountPrice={props.product?.offer?.akcia}
                salePrice={props.product?.offer?.price_sale}
              />
              <SelectProductsAmountButtons
                amount={props.product?.count}
                offerId={props.product?.offer?.id}
                isAvailable={props.product?.offer?.available}
              />
            </div>
          )}
        </div>
        <div className={styles["data__price-wrapper"]}>
          <p className={styles["data__vendor-code"]}>
            {`Артикул: ${props.product?.offer?.catalog?.vendorCode}`}
          </p>
          <div className={styles["data__price-wrapper--price"]}>
            <span>{formatPrice(minimalPrice)} ₽</span>
          </div>
        </div>
        {!deviceType.isMobile && (
          <CartItemProductActions
            offerId={props.product?.offer?.id}
            offer={props.product?.offer}
            setIsModal={props.setIsModal}
            setIdDelProduct={props.setIdDelProduct}
          />
        )}
      </div>
      {deviceType.isMobile && (
        <CartItemProductActions
          offerId={props.product?.offer?.id}
          containerStyles={styles.actions}
          offer={props.product?.offer}
          setIsModal={props.setIsModal}
          setIdDelProduct={props.setIdDelProduct}
        >
          <SelectProductsAmountButtons
            amount={props.product?.count}
            offerId={props.product?.offer?.id}
            isAvailable={props.product?.offer?.available}
          />
        </CartItemProductActions>
      )}
    </div>
  );
};

const propTypes = {
  product: PropTypes.object.isRequired,
  setIsModal: PropTypes.func,
  setIdDelProduct: PropTypes.func,
};

CartProductItem.propTypes = propTypes;

export default CartProductItem;
