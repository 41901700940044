import React from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import useDidUpdate from "../../../../../../utils/hooks/useDidUpdate";

import FilterButtonItem from "../FilterButton/FilterButtonItem";
import SearchInput from "../FilterButton/SearchInput";
import LoadingItem from "../../../../../../components/LoadingItem";

import styles from "./styles.module.scss";

const INIT_TRIM_VALUE = 4;
const MAX_FILTERS_TO_SHOW_INPUT = 10;

const FiltersList = (props) => {
  const deviceType = useSelector((state) => state.user.deviceType);
  const [currentFilters, setCurrentFilters] = React.useState(props.filters);
  const [trimNumber, setTrimNumber] = React.useState(INIT_TRIM_VALUE);
  const [isError, setIsError] = React.useState(false);

  const showShowMoreButton =
    props.filters.length >= INIT_TRIM_VALUE &&
    props.filters.length !== trimNumber;

  const showSearchInput =
    Object.entries(props.filters).length > MAX_FILTERS_TO_SHOW_INPUT;

  useDidUpdate(() => {
    setCurrentFilters(props.filters);
  }, [props.filters]);

  const changeCurrentFilters = React.useCallback(({ filters, isError }) => {
    setCurrentFilters(filters);
    setIsError(isError);
  }, []);

  const handleShowMore = React.useCallback(() => {
    if (props.showMoreFilters) {
      const findedItem = currentFilters.find((item) => item.main_rewrite_name);
      props.showMoreFilters(findedItem.main_rewrite_name);
      return;
    }
    setTrimNumber(props.filters.length);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters.length]);

  return (
    <>
      <CSSTransition
        in={props.isOpen}
        timeout={100}
        classNames={{
          enterActive: styles["filters-list-enter-active"],
          enterDone: styles["filters-list-enter-done"],
          exitActive: styles["filters-list-exit-active"],
          exitDone: styles["filters-list-exit-done"],
        }}
        mountOnEnter
      >
        {!props.filters?.length && !isError ? (
          <div className={styles["filters-list"]}>
            <LoadingItem
              figureStyles={styles.loading__figure}
              titleStyles={styles.loading__title}
            />
          </div>
        ) : (
          <div className={styles["filters-list"]}>
            {showSearchInput && !deviceType.isMobile && (
              <SearchInput
                filters={props.filters}
                changeCurrentFilters={changeCurrentFilters}
              />
            )}
            <div className={styles["list-wrapper"]}>
              <ul className={styles.list}>
                {currentFilters?.map((item, idx) => {
                  if (idx >= trimNumber) {
                    return null;
                  }

                  return (
                    <FilterButtonItem
                      key={item.rewrite_name}
                      item={item}
                      onClickSelectFilter={props.onClickSelectFilter}
                    />
                  );
                })}
              </ul>
              {showShowMoreButton && (
                <button
                  className={styles["show-more__button"]}
                  onClick={handleShowMore}
                >
                    Показать все
                </button>
              )}
            </div>
            {isError && <p className={styles.error}>Ничего не найдено</p>}
          </div>
        )}
      </CSSTransition>
    </>
  );
};

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      "rewrite_name": PropTypes.string,
      "main_rewrite_name": PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ),
  onClickSelectFilter: PropTypes.func.isRequired,
  showMoreFilters: PropTypes.func,
};

FiltersList.propTypes = propTypes;

export default FiltersList;
