import { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";
import questionMark from "../../../../../../assets/icons/svg/question-mark.svg";
import ModalMarkDownInfo from "../../../../../../components/UI/ModalMarkDownInfo/ModalMarkDownInfo";
import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import ContentMarkDownInfo from "../../../../../../components/UI/ContentMarkDownInfo/ContentMarkDownInfo";
import { useSelector } from "react-redux";
import { formatPrice } from "utils/helpers/formatedPrice";

const ProductOffersListItem = (props) => {
  const [isModalMarkDown, setIsModalMarkDown] = useState(false);
  const refModalMarkDown = useRef(null);
  const { deviceType } = useSelector(state => state.user);

  const offerTitle = useMemo(() => {
    const currentOfferVolume = props.offer.offerVolumes.find((item) => {
      return props.offer.name.includes(item.value_type);
    });
    if (!currentOfferVolume) {
      return {
        volume: "",
        description: "",
      };
    }

    return {
      volume: `${currentOfferVolume.value} ${currentOfferVolume.value_type}`,
      description: props.offer.name.split(currentOfferVolume.value_type)[1]?.trim(),
    };
  }, [props.offer]);

  const onClickHandler = () => {
    props.onSelectOffer({
      id: props.offer.id,
      description: props.offer.name,
      promotionPrice: props.offer.price_sale ?? props.offer.akcia,
      price: props.offer.price,
      percent: props.offer.percent,
      available: props.offer.available,
    });
  };

  const findMarkdown = useCallback(() => {
    if (!props.offer.offerGoodsTypes[0]) return false;
    const isMarkdown = props.offer.offerGoodsTypes.filter(element => element.value === "уценка");

    return !!isMarkdown[0];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickMarkdown = (e) => {
    e.stopPropagation();
    setIsModalMarkDown(true);
  };

  const isMarkDownInfo = useMemo(() => {
    isModalMarkDown && !deviceType.isTablet && !deviceType.isMobile;
  }, [isModalMarkDown, deviceType]);

  return (
    <div
      className={styles.root}
      onClick={onClickHandler}
      data-offer_id={props.offer.id}
      data-group_id={props.productId}
    >
      <span className={styles.indicator}>
        {props.isActiveOffer && <span className={styles["indicator-inner"]} />}
      </span>
      <div className={styles.offer}>
        <span className={styles.offer_volume}>
          {offerTitle.volume}
        </span>
        <span className={styles["offer_description-wrapper"]}>
          <span className={styles.offer_separator}>
            &ndash;
          </span>
          <span className={styles.offer_description}>
            {offerTitle.description}
          </span>
        </span>
      </div>
      <div className={styles["promotion-content"]}>

        {findMarkdown() &&
          <div className={styles["promotion-content-markdown"]}>
            <img src={questionMark} onClick={clickMarkdown} />
          </div>
        }

        {!!props.offer.percent &&
          <span className={styles["promotion-content_percent"]}>
            {`${props.offer.percent}%`}
          </span>
        }
        <span className={cn(
          styles["promotion-content__price"],
          { [styles["promotion-content__price--single"]]: !(props.offer.price_sale ?? props.offer.akcia) }
        )}>

          {!!(props.offer.price_sale ?? props.offer.akcia) &&
            <span itemProp="price" content={props.offer.price_sale ?? props.offer.akcia} className={styles["promotion-content__price"]}>
              {`${formatPrice(props.offer.price_sale) ?? formatPrice(props.offer.akcia)}\u202F`}
              <span itemProp="priceCurrency" content="RUB">₽</span>
            </span>
          }
          <span className={styles["promotion-content__price--through"]}>{`${formatPrice(props.offer.price)}\u202F₽`}</span>
        </span>
        {isMarkDownInfo && <ModalMarkDownInfo onClose={() => setIsModalMarkDown(false)} modalRef={refModalMarkDown} />}
        <BottomSheet
          isOpen={isModalMarkDown && (deviceType.isTablet || deviceType.isMobile)}
          onClose={() => setIsModalMarkDown(false)}
        >
          <ContentMarkDownInfo onClose={() => setIsModalMarkDown(false)} />
        </BottomSheet>
      </div>
    </div>
  );
};

const propTypes = {
  productId: PropTypes.number,
  offer: PropTypes.shape({
    available: PropTypes.bool,
    akcia: PropTypes.number,
    bestseller: PropTypes.bool,
    "catalog_name": PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    "new_label": PropTypes.bool,
    offerVolumes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        value: PropTypes.number,
        "value_type": PropTypes.string,
      })
    ),
    percent: PropTypes.number,
    price: PropTypes.number,
    "price_sale": PropTypes.number,
    offerGoodsTypes: PropTypes.array
  }),
  isActiveOffer: PropTypes.bool,
  onSelectOffer: PropTypes.func,
};

ProductOffersListItem.propTypes = propTypes;

export default ProductOffersListItem;