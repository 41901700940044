import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import useSearchParamsHandler from "../../../utils/hooks/useSearchParamsHandler";
import { getCatalogFilters } from "../../../store/catalog/catalogThunks";
import { setAdditionalParams, setCatalogCategory } from "../../../store/catalog";
import { AdditionalSearchOptionsEnum } from "../../../utils/constants/catalogFilter";

const useCatalogFilters = () => {
  const catalog = useSelector((state) => state.catalog);
  const { searchParams } = useSearchParamsHandler();

  const dispatch = useDispatch();
  const location = useLocation();

  const currentCategory = React.useMemo(() => {
    const currentMatch = location.pathname.match(/kosmetika|parfumeria/g);
    if (!currentMatch) {
      return "";
    }
    return currentMatch[0];
  }, [location.pathname]);

  React.useEffect(() => {
    dispatch(setCatalogCategory(currentCategory));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  React.useEffect(() => {
    const params = Object.fromEntries(searchParams);
    const additionalOptions = Object.keys(AdditionalSearchOptionsEnum).reduce((acc, cur) => {
      if (!params[cur]) {
        return acc;
      }
      acc = {
        ...acc,
        [cur]: params[cur],
      };
      return acc;
    }, {});

    dispatch(setAdditionalParams({
      value: additionalOptions,
      shouldTriggerSearch: false,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const promise = dispatch(getCatalogFilters({
      categories: currentCategory || undefined,
      searchParams,
    }));

    return () => {
      promise.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog.searchTrigger]);
};

export default useCatalogFilters;