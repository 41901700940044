import { useState, useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { userApi } from "../api/userApi";
import { citiesApi } from "../api/citiesApi";
import { setDeviceType, setUser, setUserCurrentCity } from "../store/user/user";
import { getUserCart } from "../store/user/cartThunks/cartThunks";

import {
  getUserCityStorage,
  getUserToken,
  setTokens,
} from "../utils/localStorage";
import { catalogPaths, personalAreaPaths } from "../utils/constants/routes";
import { createYMapsScript } from "../utils/ymapsUtils";
import getDeviceType from "../utils/getDeviceType";

import StickyHeader from "../layouts/Header/StickyHeader";
import MobileBottomNavigation from "../pages/Main/components/MobileBottomNavigation";
import AdditionalHeader from "../layouts/AdditionalHeader";
import ProtectedRoute from "../components/ProtectedRoute";
import LoadingItem from "../components/LoadingItem";
import Footer from "../layouts/Footer/Footer";
import {
  AboutUsPage,
  ContactsPage,
  Catalog,
  ForCompaniesPage,
  HowBuyPage,
  MainPage,
  PaymentPage,
  PresentCertificatePage,
  SaleSystemPage,
  ReturnAndExchange,
  WorkSchedulePage,
  SingleProductPage,
  SignUp,
  PersonalArea,
  SignIn,
  NoIndexPage,
  UserCart,
  PublicOfferingPage,
  DeliveryPage,
  NotFoundPage,
  PaymentStatusPage,
} from "../pages";
import RestorePassword from "../pages/SignIn/components/RestorePassword";
import RestoreByPhone from "../pages/SignIn/components/RestoreByPhone";
import RestoreByEmail from "../pages/SignIn/components/RestoreByEmail";
import RestorePasswordByAction from "../pages/SignIn/components/RestoreByPhone/RestorePasswordByAction";
import Checkout from "../pages/UserCart/Checkout/Checkout";
import MobileStickyHeader from "../layouts/MobileStickyHeader/MobileStickyHeader";
import { DEFAULT_CITY } from "../utils/constants";
import { getErrorMessage } from "../utils/helpers/errorHelper";

const App = () => {
  const { user, deviceType } = useSelector(state => state.user);
  const [isInit, setIsInit] = useState(false);
  const dispatch = useDispatch();

  const router = useLocation();

  useLayoutEffect(() => {
    window.prerenderReady = false;
  }, []);

  useLayoutEffect(() => {
    if (personalAreaPaths.includes(router.pathname) && !deviceType.isMobile) {
      return;
    }
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    const token = getUserToken();
    dispatch(getUserCart({}));

    if (!token || user.phone) {
      return setIsInit(true);
    }

    (async () => {
      try {
        const userData = await userApi.checkUser();

        if (userData.message) {
          const refreshData = await userApi.refreshUser();

          setTokens({
            token: refreshData.response.token,
            refreshToken: refreshData.response.refresh,
          });

          const refreshedUserData = await userApi.checkUser();
          dispatch(setUser(refreshedUserData.response));
        } else {
          dispatch(setUser(userData.response));
        }
      } catch (error) {

        const errorMessage = getErrorMessage(error);
        // eslint-disable-next-line no-console
        console.error("Ошибка при попытке обновить refresh_token", errorMessage);
      } finally {
        setIsInit(true);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getUserCityStorage) {
      dispatch(setUserCurrentCity(getUserCityStorage));
    } else {
      (async () => {
        try {
          const data = await citiesApi.getUserCity();
          if (!data.response) {
            dispatch(setUserCurrentCity(DEFAULT_CITY));
          } else {
            dispatch(setUserCurrentCity(data.response));
          }
        } catch (error) {
          // TODO
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    createYMapsScript();
    dispatch(setDeviceType(getDeviceType()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timerId;
    const handleResize = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        dispatch(setDeviceType(getDeviceType()));
      }, 100);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    const onPageLoad = () => {
      const script = document.createElement("script");
      script.innerHTML = "window.prerenderReady = true;";
      document.body.appendChild(script);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);

      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  if (!isInit) {
    return <LoadingItem />;
  }

  return (
    <>
      {deviceType.isMobile ? (
        <>
          <MobileStickyHeader />
          <MobileBottomNavigation />
        </>
      ) : (
        <>
          <StickyHeader />
          <AdditionalHeader />
        </>
      )}
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='product/:productId' element={<SingleProductPage />} />
        {catalogPaths.map((path, idx) => {
          return <Route key={idx} path={path} element={<Catalog />} />;
        })}
        <Route path='sign-in'>
          <Route
            index
            element={
              <ProtectedRoute>
                <SignIn />
              </ProtectedRoute>
            }
          />
          <Route path='restore'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <RestorePassword />
                </ProtectedRoute>
              }
            />
            <Route path='by-phone'>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <RestoreByPhone />
                  </ProtectedRoute>
                }
              />
              <Route
                path='call-me'
                element={
                  <ProtectedRoute>
                    <RestorePasswordByAction restoreType='callMe' />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path='call'
                element={
                  <ProtectedRoute>
                    <RestorePasswordByAction restoreType='call' />
                  </ProtectedRoute>
                }
              />
              <Route
                path='sms'
                element={
                  <ProtectedRoute>
                    <RestorePasswordByAction restoreType='sms' />
                  </ProtectedRoute>
                }
              /> */}
            </Route>
            <Route
              path='by-email'
              element={
                <ProtectedRoute>
                  <RestoreByEmail />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route
          path='sign-up'
          element={
            <ProtectedRoute>
              <SignUp />
            </ProtectedRoute>
          }
        />
        {personalAreaPaths.map((path, idx) => {
          return (
            <Route
              key={idx}
              path={path}
              element={
                <ProtectedRoute isLogin>
                  <PersonalArea />
                </ProtectedRoute>
              }
            />
          );
        })}
        <Route path='/info'>
          <Route path='oplata' element={<PaymentPage />} />
          <Route path='kontakty' element={<ContactsPage />} />
          <Route path='grafik-raboty' element={<WorkSchedulePage />} />
          <Route
            path='podarocnye-sertifikaty'
            element={<PresentCertificatePage />}
          />
          <Route path='vozvrat-i-obmen' element={<ReturnAndExchange />} />
          <Route path='sistema-skidok' element={<SaleSystemPage />} />
          <Route path='dlya-yuridiceskix-lic' element={<ForCompaniesPage />} />
          <Route path='o-nas' element={<AboutUsPage />} />
          <Route path='kak-zakazat' element={<HowBuyPage />} />
          <Route path='publichnaya-oferta' element={<PublicOfferingPage />} />
          <Route path='dostavka' element={<DeliveryPage />} />
        </Route>
        <Route path='cart'>
          <Route index element={<UserCart />} />
          <Route path='checkout' element={<Checkout />} />
        </Route>
        <Route path='payment-status' element={<PaymentStatusPage />} />
        <Route path='/login/vendor' element={<NoIndexPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
