import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import MultiRangeSlider from "./MultiRangeSlider";

import styles from "./styles.module.scss";

const PriceFilter = (props) => {
  const {filters} = useSelector((state) => state.catalog);
  const priceFilters = useMemo(() => {
    if (!filters?.prices) {
      return null;
    }

    return filters.prices[0];
  }, [filters]);


  const [searchParams] = useSearchParams();

  const minPrice = useMemo(() => {
    const isValidParamsValue = !isNaN(+searchParams.get("max_price"));
    return isValidParamsValue
      ? +(searchParams.get("min_price"))
      : priceFilters?.min;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const maxPrice = useMemo(() => {
    const isValidParamsValue = !isNaN(+searchParams.get("max_price"));

    return isValidParamsValue
      ? +(searchParams.get("max_price"))
      : priceFilters?.max;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const currentSelectedPrice = useMemo(() => {
    return {
      "min_price": +props.minPrice,
      "max_price": +props.maxPrice,
    };
  }, [props.minPrice, props.maxPrice]);

  const onChangePrice = (min, max) => {
    if ((+(searchParams.get("min_price") || priceFilters?.min) !== min)) {
      if (min === priceFilters?.min) {
        if (props.minPrice === min) {
          return;
        }
        props.removePriceOption({
          priceType: "min_price",
        });
      } else {
        props.setPriceOption({
          priceType: "min_price",
          value: String(min),
        });
      }
    }

    if ((+(searchParams.get("max_price") || priceFilters?.max) !== max)) {
      if (max === priceFilters?.max) {
        if (props.maxPrice === max) {
          return;
        }
        props.removePriceOption({
          priceType: "max_price",
        });
      } else {
        props.setPriceOption({
          priceType: "max_price",
          value: String(max),
        });
      }
    }
  };

  if (!priceFilters) {
    return null;
  }

  return(
    <div className={styles.root}>
      <h3 className={styles.title}>
        Цена
      </h3>
      {
        priceFilters.min && priceFilters.max
          ? <MultiRangeSlider
            min={priceFilters.min}
            max={priceFilters.max}
            minPrice={minPrice}
            maxPrice={maxPrice}
            currentSelectedPrice={currentSelectedPrice}
            onChange={onChangePrice}
          />
          : null
      }
    </div>
  );
};

const propTypes = {
  minPrice: PropTypes.string.isRequired,
  maxPrice: PropTypes.string.isRequired,
  setPriceOption: PropTypes.func.isRequired,
  removePriceOption: PropTypes.func.isRequired,
};

PriceFilter.propTypes = propTypes;

export default PriceFilter;