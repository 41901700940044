import { useSelector, useDispatch } from "react-redux";

import {
  resetCatalogFilters,
  setAdditionalParam,
  toggleSelectedFilterStatus,
} from "../../../../../store/catalog";
import useSearchParamsHandler from "../../../../../utils/hooks/useSearchParamsHandler";
import { getResultStringToSaveInQuery } from "../../../../../utils/helpers/searchParamsHelper";

import MainFilter from "../MainFilter";

const DesktopFilterSection = () => {
  const catalog = useSelector((state) => state.catalog);
  const { searchParams, onDeleteParams, handleChangeSingleSearchParam } = useSearchParamsHandler();

  const dispatch = useDispatch();

  const handleSelectFilter = (filter) => {
    const mainKey = filter.main_rewrite_name;

    const resultString = getResultStringToSaveInQuery({
      newItem: filter.rewrite_name,
      currentQuery: searchParams.get(mainKey)
    });
    handleChangeSingleSearchParam({
      key: mainKey,
      queryString: resultString
    });

    handleChangeSingleSearchParam({ key: "limit", queryString: "" });
    handleChangeSingleSearchParam({ key: "offset", queryString: "" });

    dispatch(toggleSelectedFilterStatus({ filter }));
  };

  const handleSelectPrice = ({ priceType, value }) => {
    dispatch(setAdditionalParam({ type: priceType, value, shouldTriggerSearch: true }));
    handleChangeSingleSearchParam({
      key: priceType,
      queryString: value,
    });
  };

  const handleRemovePriceOption = ({ priceType }) => {
    dispatch(setAdditionalParam({ type: priceType, value: "", shouldTriggerSearch: true }));
    handleChangeSingleSearchParam({
      key: priceType,
      queryString: "",
    });
  };

  const handleResetAllFilters = () => {
    const formattedSearchParams = Object.fromEntries(searchParams);
    const selectedFiltersGroupNames = Object.keys(formattedSearchParams);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
    const { sort_by, order_by, ...rest } = formattedSearchParams;
    dispatch(resetCatalogFilters(selectedFiltersGroupNames));
    onDeleteParams(Object.keys(rest ?? {}));
  };

  return (
    <MainFilter
      filters={catalog.filters}
      minPrice={catalog.additionalParams.min_price}
      maxPrice={catalog.additionalParams.max_price}
      onClickSelectFilter={handleSelectFilter}
      setPriceOption={handleSelectPrice}
      removePriceOption={handleRemovePriceOption}
      handleResetAllFilters={handleResetAllFilters}
    />
  );
};

export default DesktopFilterSection;
