export interface IErrorData {
    message: string
    code?: number
}

export interface IMeta {
    error: IError | string | null
    datetime: number
}

export interface IError {
    message: string
    code: number
}

export interface IGenericResponseRoot<T> {
    response: T | null
    meta: IMeta
}

// тайпгард для проверки если ошибка типа IError
const typeguardIError = (data: unknown): data is IError => {
  return (
    typeof data === "object" &&
        data !== null &&
        typeof (data as IError)?.message === "string" &&
        typeof (data as IError)?.code === "number"
  );
};
// тайпгард для проверки если ошибка типа строка или null
const typeguardErrorString = (data: unknown): data is { error: string } => {
  return (
    typeof data === "object" &&
        data !== null &&
        "error" in data &&
        typeof (data as { error: string }).error === "string"
  );
};
// тайпгард для проверки общего объекта ответа от сервера
const typeguardGenericResponseRoot = <T>(data: unknown): data is IGenericResponseRoot<T> => {
  return (
    typeof data === "object" &&
        data !== null &&
        "response" in data &&
        ("meta" in data && typeof (data as IGenericResponseRoot<T>).meta === "object")
  );
};
// тайпгард на общую ошибку типа Error
const typeguardError = (data: unknown): data is Error => {
  return (
    data instanceof Error
  );
};

export const getErrorData = (error: unknown): IErrorData => {
  const defaultErrorObj: IErrorData = {
    message: "Произошла необработанная ошибка"
  };

  if (typeof error !== "object") {
    return defaultErrorObj;
  }

  if (typeguardGenericResponseRoot(error)) {
    if (typeguardIError(error.meta.error)) {
      const genericReponseErrorData: IErrorData = {
        message: error.meta.error.message,
        code: error.meta.error.code
      };

      return genericReponseErrorData;
    }

    if (typeguardErrorString(error.meta)) {
      const genericReponseErrorData: IErrorData = {
        message: error.meta.error
      };

      return genericReponseErrorData;
    }
  }

  if (typeguardError(error)) {
    const errorObjFromError: IErrorData = {
      message: error.message
    };

    return errorObjFromError;
  }

  return defaultErrorObj;
};
