import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { deliveryApi } from "../../api/deliveryApi";
import useDebounce from "../../utils/hooks/useDebounce";
import { setDeliveryCoordinates, setOrderCity, setUserDeliveryAddress } from "../../store/user/user";
import { getCityBySearchAddress } from "../ymapsUtils";

const useDelivery = (options) => {
  const { currentCity, checkoutData } = useSelector((state) => state.user);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const {
    searchValue,
    isFocusInput,
    setSearchValue,
    city,
  } = options;

  const debouncedValue = useDebounce(searchValue, 500);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFocusInput || searchValue.length < 3) return;
    (async() => {
      try {
        const options = {
          query: searchValue,
          city: city || checkoutData.orderCity || currentCity,
          lat: "",
          lon: "",
        };
        const data = await deliveryApi.getDeliveryAddress(options);
        if (data.meta.error) {
          throw new Error(data.meta.error);
        }

        if (!data.response.items) {
          setDeliveryAddresses([]);
          return;
        }
        setDeliveryAddresses(data.response.items);
      } catch(err) {
        setDeliveryAddresses([]);
        // TODO
        // eslint-disable-next-line no-console
        console.error(err?.message);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocusInput, debouncedValue, city]);

  const setDeliveryAddressByPosition = async() => {
    if (!navigator.geolocation) {
      return toast("Не можем определить ваше местоположение");
    }

    navigator.geolocation.getCurrentPosition(
      async(position) => {
        try {
          const options = {
            query: "",
            city: "",
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          dispatch(setDeliveryCoordinates([position.coords.latitude, position.coords.longitude]));

          const data = await deliveryApi.getDeliveryAddress(options);

          if (data.meta.error) {
            return toast(data.meta.error);
          }

          if (!data.response.items.length) {
            setDeliveryAddresses([]);
            return;
          }
          const firstAddress = data.response.items[0];

          setDeliveryAddresses(data.response.items);
          dispatch(setUserDeliveryAddress(firstAddress));
          setSearchValue(firstAddress);

          const [city] = await getCityBySearchAddress(firstAddress);
          if (city) {
            dispatch(setOrderCity(city));
          }
        } catch(err) {
          // TODO
        }
      },
      (error) => {
        switch (error.code) {
        case error.PERMISSION_DENIED:
          toast("Необходимо дать разрешение на определение местоположения");
          break;
        case error.POSITION_UNAVAILABLE:
          toast("Не можем определить ваше местоположение");
          break;
        case error.TIMEOUT:
          toast("Не можем определить ваше местоположение");
          break;
        default:
          toast("Не можем определить ваше местоположение");
          break;
        }
      }
    );
  };

  return {
    deliveryAddresses,
    setDeliveryAddressByPosition,
  };
};

export default useDelivery;