import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { brandsApi } from "../../../api/brands";
import useShowShadow from "../../../utils/hooks/useShowShadow";

import CurrentBrandLetterBlock from "./components/CurrentBrandLetterBlock";
import LettersRow from "./components/LettersRow";

import cn from "classnames";
import styles from "./styles.module.scss";
import { ARR_NUMBERS, CYRILLIC_ALPHABET } from "../../../utils/constants/arrAlphabets";
import { useDispatch } from "react-redux";
import { toggleSearchTrigger } from "../../../store/catalog";

const BrandsByLetterContent = (props) => {
  const [brands, setBrands] = useState({});
  const [selectedLetter, setSelectedLetter] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");
  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow();
  const collator = new Intl.Collator("ru");

  const dispatch = useDispatch();
  const brandsRef = useRef();

  const getBrands = (response) => {
    const items = [];

    if (typeof response === "object") {
      for (const key in response) {
        items.push(...response[key]);
      }
    }

    return items;
  };

  const brandsSort = (brands, letter) => {

    const brandsFilter = brands.reduce((previous, current) => {
      if (!previous.filter(item => item.brand["rewrite_name"] === current.brand["rewrite_name"])[0]) {
        previous.push(current);
      }
      return previous;
    }, []);

    if (letter === "А-Я") return brandsFilter.sort((a, b) => collator.compare(a.brand.name, b.brand.name));
    if (letter === "0-9") return brandsFilter.sort((a, b) => a.brand.name > b.brand.name ? 1 : -1);

    return brandsFilter.sort((a, b) => {
      const aBrand = a.brand.name.toLowerCase().replace(/[^a-zA-Z]/gi, "");
      const bBrand = b.brand.name.toLowerCase().replace(/[^a-zA-Z]/gi, "");

      if (aBrand > bBrand) return 1;
      if (aBrand < bBrand) return -1;
      return 0;
    });
  };

  const brandsByLetters = async (letters, letter) => {
    try {
      const res = await brandsApi.getBrandsByLetters(letters);
      if (!res.response) return;
      const kosmetika = getBrands(res.response.kosmetika);
      const parfumeria = getBrands(res.response.parfumeria);
      const allBrands = brandsSort([...kosmetika, ...parfumeria], letter);

      setBrands({
        all: allBrands,
        kosmetika,
        parfumeria,
      });
    } catch (err) {
      // TODO
    }
  };

  useEffect(() => {
    onSelectLetter("A");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!brandsRef.current) {
      return;
    }

    changeShowShadow({
      top: false,
      bottom: brandsRef.current.scrollHeight > brandsRef.current.clientHeight,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]);

  const onSelectLetter = async (letter) => {
    if (letter === selectedLetter) return;

    setSelectedLetter(letter);

    if (letter === "А-Я") return brandsByLetters(CYRILLIC_ALPHABET, letter);
    if (letter === "0-9") return brandsByLetters(ARR_NUMBERS, letter);

    brandsByLetters([letter], letter);
  };

  const onSelectFilter = (filter) => {
    if (filter === activeFilter) {
      return;
    }
    setActiveFilter(filter);
  };

  const handlerClickLink = () => {
    dispatch(toggleSearchTrigger());

    props.onClose();
  };

  return (
    <>
      <CurrentBrandLetterBlock
        selectedLetter={selectedLetter}
        activeFilter={activeFilter}
        brands={brands}
        onSelectFilter={onSelectFilter}
      />
      <div className={styles["content-wrapper"]}>
        <LettersRow selectedLetter={selectedLetter} onSelectLetter={onSelectLetter} />
        <div
          className={cn(
            styles["brands-container"],
            { [styles["brands-container--top-shadow"]]: showShadow.top },
            { [styles["brands-container--bottom-shadow"]]: showShadow.bottom },
          )}
        >
          <div className={styles.brands} onScroll={onScrollHandler} ref={brandsRef}>
            {brands[activeFilter]?.map((item, idx) => {
              const link =
                activeFilter === "all"
                  ? `/catalog?brands=${item.brand.rewrite_name}`
                  : `/catalog/${activeFilter}/?brands=${item.brand.rewrite_name}`;

              return (
                <NavLink
                  key={`${item.brand.rewrite_name}_${idx}`}
                  to={link}
                  onClick={handlerClickLink}
                  className={styles.brands__item}
                >
                  {item.brand.name}
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

BrandsByLetterContent.propTypes = propTypes;

export default BrandsByLetterContent;
