import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserFavoriteProducts } from "../../../../store/user/userThunks/userThunks";

import SelectedProducts from "./SelectedProducts";
import AnchorsBlock from "../components/AnchorsBlock";

import styles from "./styles.module.scss";

const UserSelectedProducts = () => {
  const { user, deviceType } = useSelector((state) => state.user);
  const [, setUpdate] = useState(false);

  const dispatch = useDispatch();

  const favouriteProductsRef = useRef([user.favouriteProducts]);
  const subscriptionProductsRef = useRef([]);
  const favouritesAnchorRef = useRef(null);
  const subscriptionAnchorRef = useRef(null);

  const anchors = useMemo(() => {
    return [
      {
        id: 0,
        title: "Товары",
        ref: favouritesAnchorRef,
        offsetTop: 200,
      },
      {
        id: 1,
        title: "Подписка на товары",
        ref: subscriptionAnchorRef,
        offsetTop: 90,
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(getUserFavoriteProducts());
  }, [dispatch]);

  useEffect(() => {
    if (user.favouriteProducts.length !== favouriteProductsRef.current.length) {
      favouriteProductsRef.current = user.favouriteProducts;
      setUpdate((prev) => !prev);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.root}>
      <section className={styles["products-wrapper"]}>
        {!favouriteProductsRef.current.length &&
          !subscriptionProductsRef.current.length && (
          <h3 className={styles.title}>Еще ничего не добавлено</h3>
        )}
        {!!favouriteProductsRef.current.length && (
          <SelectedProducts
            ref={favouritesAnchorRef}
            title="Товары"
            products={favouriteProductsRef.current.flat()}
          />
        )}
        {!!subscriptionProductsRef.current.length && (
          <SelectedProducts
            ref={subscriptionAnchorRef}
            title="Подписка на товары"
            products={subscriptionProductsRef.current.flat()}
          />
        )}
      </section>
      <div id="diginetica_rec_sys"></div>
      {!!favouriteProductsRef.current.length &&
        !!subscriptionProductsRef.current.length &&
        !deviceType.isMobile && <AnchorsBlock anchors={anchors} />}
    </section>
  );
};

export default UserSelectedProducts;

