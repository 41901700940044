import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import CustomLink from "../../../../../../../components/CustomLink";

import cn from "classnames";
import styles from "./styles.module.scss";
import { AboutProduct } from "./Tabs/AboutProduct/AboutProduct";
import { AboutBrand } from "./Tabs/AboutBrand/AboutBrand";
import { ProductComposition } from "./Tabs/ProductComposition/ProductComposition";

const tabs = [
  {
    id: 0,
    title: "О товаре",
    Tab: AboutProduct
  },
  {
    id: 1,
    title: "О бренде",
    Tab: AboutBrand
  },
  {
    id: 2,
    title: "Состав",
    Tab: ProductComposition
  },
];

const renderingTabCondition = (tab, props) => {
  if (tab.id === 1) return process.env.REACT_APP_COMPONENT_ENABLE_ABOUT_OFFER_BRAND === "false" || !props.brandDescription;
  if (tab.id === 2) return true;
};

const ProductDescriptionInfo = (props) => {

  const tabsFiltered = useMemo(() => {
    return tabs.filter((tab) => !renderingTabCondition(tab, props));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentDescriptionButtonId, setCurrentDescriptionButtonId] = useState(tabsFiltered?.[0].id);

  const serialProduct = useMemo(() => {
    return props.properties.find((item) => {
      return (
        item.property_value?.property?.rewrite_name?.toLowerCase() === "seria"
      );
    });
  }, [props.properties]);

  const onClickToggleProductDescription = (id) => {
    setCurrentDescriptionButtonId(id);
  };

  return (
    <div className={styles.root}>

      <div className={styles["description-header"]}>
        {tabsFiltered.map((item) => {
          return (
            <button
              key={item.id}
              onClick={() => onClickToggleProductDescription(item.id)}
              className="description-header__button"
            >
              <h4
                className={cn(styles["description-header__title"], {
                  [styles["description-header__title--active"]]:
                    currentDescriptionButtonId === item.id,
                })}
              >
                {item.title}
              </h4>
            </button>
          );
        })}
      </div>

      {tabsFiltered.map(({ Tab, id }) => {
        if (id === currentDescriptionButtonId) return <Tab key={id} {...props} />;
      })}

      <div className={styles["buttons-wrapper"]}>
        <CustomLink
          path={`/catalog?brands=${props.brandRewriteName}`}
          title={`Все товары ${props.brandTitle}`}
          isBlack
        />
        {serialProduct && (
          <CustomLink
            path={`/catalog?${serialProduct.property_value.property.rewrite_name}=${serialProduct.property_value.rewrite_name}`}
            title={`Все товары серии ${serialProduct.property_value.name}`}
            isWhite
          />
        )}
        {props.category && (
          <CustomLink
            path={`/catalog/${props.category.rewrite_name}`}
            title={`Вся ${props.category.name.toLowerCase()}`}
            isWhite
          />
        )}
      </div>

    </div>
  );
};

const propTypes = {
  brandTitle: PropTypes.string.isRequired,
  brandRewriteName: PropTypes.string.isRequired,
  description: PropTypes.string,
  productRewrateName: PropTypes.string.isRequired,
  category: PropTypes.object,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      "property_id": PropTypes.number,
      "property_name": PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    })
  ),
  brandDescription: PropTypes.string,
  scrollToReviews: PropTypes.func.isRequired,
};

ProductDescriptionInfo.propTypes = propTypes;

export default ProductDescriptionInfo;
