import { useCallback, useEffect } from "react";

interface UseOutsideClickProps {
  ref: React.RefObject<HTMLElement>;
  cb: () => void;
  disableClickOutside?: boolean;
}

const useOutsideClick = ({ ref, cb, disableClickOutside }: UseOutsideClickProps) => {
  const handleClickOutside = useCallback(
    ({ target }: MouseEvent) => {
      if (ref.current && !ref.current.contains(target as Node)) {
        if (
          (typeof disableClickOutside === "boolean" && disableClickOutside) ||
          !!(target as HTMLElement).closest(".Toastify")
        ) {
          return;
        }
        cb();
      }
    },
    [cb, disableClickOutside, ref]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);
};

export default useOutsideClick;