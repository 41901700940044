import { useDispatch, useSelector } from "react-redux";
import React from "react";
import PropTypes from "prop-types";

import useShowShadow from "../../../../utils/hooks/useShowShadow";
import useBSCatalogFilters from "../../hooks/useBSCatalogFilters";
import useSearchParamsHandler from "../../../../utils/hooks/useSearchParamsHandler";
import { resetCatalogFilters, setAdditionalParam, setCatalogState, toggleSearchTrigger, toggleSelectedFilterStatus } from "../../../../store/catalog";
import { getResultStringToSaveInQuery } from "../../../../utils/helpers/searchParamsHelper";

import { ReactComponent as FilterIcon } from "../../../../assets/icons/svg/filter-icon.svg";
import BottomSheet from "../../../../components/UI/Bottomsheet/Bottomsheet";
import SelectedFilters from "../SelectedFilters";
import MainFilter from "../CatalogFilters/MainFilter";
import CustomButton from "../../../../components/CustomButton";
import ShowMoreFilterItemsListBS from "../CatalogFilters/MainFilter/ShowMoreFilterItemsListBS";

import cn from "classnames";
import styles from "./styles.module.scss";

const MobileSelectedFiltersButton = (props) => {
  const catalog = useSelector((state) => state.catalog);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSheetMount, setIsSheetMount] = React.useState(false);
  const [isMoreFiltersBSOpen, setIsMoreFiltersBSOpen] = React.useState(false);

  const {
    searchParams,
    onDeleteParams,
    handleChangeSingleSearchParam
  } = useSearchParamsHandler();

  const catalogPrevState = React.useRef({});
  const shouldReturnPrevState = React.useRef(false);
  const isPrevStateSetted = React.useRef(false);

  const dispatch = useDispatch();

  const {
    selectedShowMoreKey,
    selectShowMoreItemsKey,
  } = useBSCatalogFilters();

  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow();

  const amountActiveFilters = React.useMemo(() => {
    let resultAmount = Object.keys(catalog.selectedFilters ?? {}).length;

    const { min_price: minPrice, max_price: maxPrice } = catalog.additionalParams;

    if (!!minPrice || maxPrice) {
      resultAmount++;
    }

    return resultAmount;
  }, [catalog.selectedFilters, catalog.additionalParams]);

  React.useEffect(() => {
    if (isPrevStateSetted.current) {
      return;
    }

    catalogPrevState.current = {
      catalog,
      params: Object.fromEntries(searchParams),
    };

    if (Object.values(catalog.filters).length > 0) {
      isPrevStateSetted.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, catalog]);

  const handleSelectFilter = (filter, skipSearchTrigger) => {
    const mainKey = filter.main_rewrite_name;

    const resultString = getResultStringToSaveInQuery({
      newItem: filter.rewrite_name,
      currentQuery: searchParams.get(mainKey)
    });

    handleChangeSingleSearchParam({
      key: mainKey,
      queryString: resultString
    });

    handleChangeSingleSearchParam({ key: "limit", queryString: "" });
    handleChangeSingleSearchParam({ key: "offset", queryString: "" });

    dispatch(toggleSelectedFilterStatus({
      filter,
      skipSearchTrigger,
    }));
  };

  const handleSelectPrice = ({ priceType, value }) => {
    dispatch(setAdditionalParam({ type: priceType, value, shouldTriggerSearch: true }));
    handleChangeSingleSearchParam({
      key: priceType,
      queryString: value,
    });
  };

  const handleRemovePriceOption = ({ priceType }) => {
    dispatch(setAdditionalParam({ type: priceType, value: "", shouldTriggerSearch: true }));
    handleChangeSingleSearchParam({
      key: priceType,
      queryString: "",
    });
  };

  const handleResetAllFilters = () => {
    const formattedSearchParams = Object.fromEntries(searchParams);
    const selectedFiltersGroupNames = Object.keys(formattedSearchParams);

    // правило было отменено 30-08-2024 - две переменные не нужны sort_by и order_by
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
    const { sort_by, order_by, ...rest } = formattedSearchParams;
    dispatch(resetCatalogFilters(selectedFiltersGroupNames));
    onDeleteParams(Object.keys(rest ?? {}));

    shouldReturnPrevState.current = false;
    isPrevStateSetted.current = false;
    catalogPrevState.current = {};
    dispatch(toggleSearchTrigger());
    props.handleChangeShouldGetProductsStatus(true);
    setIsOpen(false);
  };

  const handleEnteredBS = () => {
    // changeShowShadow({
    //   top: false,
    //   bottom: listRef.current?.scrollHeight > listRef.current?.offsetHeight,
    // });
    props.handleChangeShouldGetProductsStatus(false);
  };

  const onExitedHandler = () => {
    changeShowShadow({
      top: false,
      bottom: true,
    });
    setTimeout(() => setIsSheetMount(false), 300);
    if (shouldReturnPrevState.current) {
      dispatch(setCatalogState(catalogPrevState.current.catalog));
      Object.entries(catalogPrevState.current.params).forEach((item) => {
        handleChangeSingleSearchParam({
          key: item[0],
          queryString: item[1] ?? "",
        });
      });
      dispatch(toggleSearchTrigger());
    }

    shouldReturnPrevState.current = false;
    isPrevStateSetted.current = false;
    catalogPrevState.current = {};
  };

  const handleCloseMainBS = React.useCallback(() => {
    setIsOpen(false);
    shouldReturnPrevState.current = true;
    props.handleChangeShouldGetProductsStatus(true);
    onDeleteParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDeleteParams, dispatch]);


  const handleOpenMainBS = React.useCallback(() => {
    catalogPrevState.current = {
      catalog,
      params: Object.fromEntries(searchParams),
    };
    isPrevStateSetted.current = Object.values(catalog.filters).length > 0;

    setTimeout(() => setIsOpen(true), 50);
    setIsSheetMount(true);
    dispatch(toggleSearchTrigger());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog, searchParams, toggleSearchTrigger, dispatch]);

  const handleSubmit = React.useCallback(() => {
    props.handleChangeShouldGetProductsStatus(true);
    setIsOpen(false);
    shouldReturnPrevState.current = false;
    dispatch(toggleSearchTrigger());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowMoreFilters = React.useCallback((key) => {
    setIsMoreFiltersBSOpen(true);
    selectShowMoreItemsKey(key);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseShowMoreFiltersBS = React.useCallback(() => {
    setIsMoreFiltersBSOpen(false);
  }, []);

  return (
    <>
      <button className={styles.root} onClick={handleOpenMainBS}>
        <FilterIcon />
        <span className={styles.title}>
          Фильтры ({`${amountActiveFilters}`})
        </span>
      </button>
      {isSheetMount ? <BottomSheet
        isOpen={isOpen}
        onClose={handleCloseMainBS}
        onEntered={handleEnteredBS}
        onExited={onExitedHandler}
        disableClickOutside={isMoreFiltersBSOpen}
        headerLineStyles={cn(styles["bottomsheet__header-line"], {
          [styles["bottomsheet__header-line-shadow"]]: showShadow.top,
        })}
        headerTitle="Фильтры"
        shouldBeOnFullScreen
      >
        <>
          <div className={styles.content} onScroll={onScrollHandler}>
            <SelectedFilters
              selectedFilters={catalog.selectedFilters}
              removeSingleFilter={handleSelectFilter}
            />
            <MainFilter
              minPrice={catalog.additionalParams.min_price}
              maxPrice={catalog.additionalParams.max_price}
              filters={catalog.filters}
              onClickSelectFilter={handleSelectFilter}
              setPriceOption={handleSelectPrice}
              removePriceOption={handleRemovePriceOption}
              showMoreFilters={handleShowMoreFilters}
              containerStyles={styles.content__filters}
            />
          </div>
          <div
            className={cn(styles["buttons-wrapper"], {
              [styles["buttons-wrapper--bottom-shadow"]]: showShadow.bottom,
            })}
          >
            <CustomButton
              title="Применить"
              className={styles["accept-filters"]}
              onClick={handleSubmit}
            />
            <button
              className={styles["reset-button"]}
              onClick={handleResetAllFilters}
            >
              Сбросить все фильтры
            </button>
          </div>
        </>
      </BottomSheet> : null}
      <ShowMoreFilterItemsListBS
        isOpen={isMoreFiltersBSOpen}
        filters={catalog.filters?.[selectedShowMoreKey] ?? []}
        selectedShowMoreKey={selectedShowMoreKey}
        onSelectFilter={handleSelectFilter}
        onClose={handleCloseShowMoreFiltersBS}
      />
    </>
  );
};

const propTypes = {
  handleChangeShouldGetProductsStatus: PropTypes.func.isRequired,
};

MobileSelectedFiltersButton.propTypes = propTypes;

export default MobileSelectedFiltersButton;
