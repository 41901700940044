import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import cn from "classnames";

import { WAY_TO_GET } from "../../../../../utils/constants";
import useOutsideClick from "../../../../../utils/hooks/useOutsideClick";
import useDelivery from "../../../../../utils/hooks/useDelivery";
import { formatAddress } from "../../../../../utils/helpers/formatedAddresses";
import {
  setDeliveryCoordinates,
  setOrderCity,
  setProviderInfo,
  setUserCurrentCity,
  setUserDeliveryAddress,
  setWayToGet,
} from "../../../../../store/user/user";

import CustomScrollbar from "../../../../../components/CustomScrollbar/CustomScrollbar";
import ProvidersTitlesList from "./components/ProvidersTitlesList/ProviderTitleItem";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

import styles from "./styles.module.scss";

const SelectAddressForOrder = (props) => {
  const { checkoutData } = useSelector((state) => state.user);
  const [searchValue, setSearchValue] = useState(
    checkoutData.recipient.address
  );
  const [showAutocompleteList, setShowAutocompleteList] = useState(false);
  const [isFocusInput, setIsFocusInput] = useState(false);
  const [selectedAddressIdx, setSelectedAddressIdx] = useState(null);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);

  const { deliveryAddresses, setDeliveryAddressByPosition } = useDelivery({
    isFocusInput,
    searchValue,
    setSearchValue,
  });
  const dispatch = useDispatch();

  const autocompleteListRef = useRef(null);
  useOutsideClick({
    ref: autocompleteListRef,
    cb: () => {
      setIsFocusInput(false);
      setShowAutocompleteList(false);
    },
  });

  useEffect(() => {
    setSearchValue(checkoutData.recipient.address);
  }, [checkoutData.recipient.address]);

  const handleChange = async (event) => {
    setSearchValue(event.target.value);
  };

  const selectAddress = async (address) => {
    try {
      const result = await window.ymaps.geocode(address);
      const coordinates = result.geoObjects.get(0).geometry.getCoordinates();
      dispatch(setUserDeliveryAddress(address));
      dispatch(setDeliveryCoordinates(coordinates));
      setSearchValue(address);
      setShowAutocompleteList(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error during geocode:", error);
      toast("Произошла ошибка. Пожалуйста, попробуйте снова.");
    }
  };


  const selectSafeAddress = async (address, idx, city) => {
    try {
      if (typeof address !== "string" || address.trim() === "") {
        throw new Error("Не верный адрес");
      }

      const result = await window.ymaps.geocode(address);
      if (!result || !result.geoObjects || result.geoObjects.getLength() === 0) {
        throw new Error("Адрес не найден. Пожалуйста, введите корректный адрес.");
      }

      const coordinates = result.geoObjects.get(0).geometry.getCoordinates();
      if (!coordinates || coordinates.length !== 2 ||
        typeof coordinates[0] !== "number" || typeof coordinates[1] !== "number" ||
        coordinates[0] < 35 || coordinates[0] > 82 ||
        coordinates[1] < 19 || coordinates[1] > 180) {
        throw new Error("Проверьте правильность адреса");
      }

      dispatch(setOrderCity(city));
      dispatch(setUserCurrentCity(city));
      dispatch(setUserDeliveryAddress(address));
      dispatch(setDeliveryCoordinates(coordinates));

      setSearchValue(address);
      setShowAutocompleteList(false);
      setSelectedAddressIdx(idx);
      localStorage.setItem("selectedAddressIdx", idx);
      await props.setCity(city);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Ошибка при выборе адреса:", error?.message);

      const knownErrors = [
        "Не верный адрес",
        "Адрес не найден. Пожалуйста, введите корректный адрес.",
        "Проверьте правильность адреса",
        "Доставка курьером недоступна в этом городе"
      ];

      const errorMessage = knownErrors.includes(error?.message)
        ? error.message
        : "Произошла ошибка. Пожалуйста, попробуйте снова.";

      return toast(errorMessage);
    }
  };

  const acceptAddress = () => {

    if (!props.chosenProvider) {
      return toast("Выберите фирму доставки");
    }

    const wayToGetKey = Object.entries(WAY_TO_GET).find((item) => {
      return item[1] === checkoutData.wayToGet;
    })[0];

    const findedItem = props.providersInfo[wayToGetKey].find(
      (item) => item.deliveryProvider === props.chosenProvider?.type
    );

    const deliveryType =
      checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup";

    dispatch(
      setProviderInfo({
        provider: findedItem.deliveryProvider,
        amount: findedItem.deliveryCost,
        daysMax: findedItem.daysMax,
        daysMin: findedItem.daysMin,
        type: deliveryType,
      })
    );
    props.onCloseModal();
  };

  const onFocusHandler = () => {
    setIsFocusInput(true);
    setShowAutocompleteList(true);
    setIsPlaceholderVisible(false);
  };

  const onBlurHandler = () => {
    setIsPlaceholderVisible(true);
  };

  const selectWayToGet = (way) => {
    dispatch(setWayToGet(way));
  };

  useEffect(() => {
    const savedIdx = localStorage.getItem("selectedAddressIdx");
    if (savedIdx !== null) {
      setSelectedAddressIdx(Number(savedIdx));
    }
  }, []);

  const checkProvidersInfo = () => {
    if (!props.providersInfo || !props.providersInfo.toDoor) {
      return;
    }

    if (props.providersInfo.toDoor.length === 0 && !isToastVisible) {
      setIsToastVisible(true);
      toast.info("Доставка курьером недоступна в этом городе", {
        onClose: () => setIsToastVisible(false)
      });
    }
  };

  useEffect(() => {
    checkProvidersInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.providersInfo]);

  return (
    <div className={styles.root}>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title={WAY_TO_GET.toPoint}
          onClick={() => selectWayToGet(WAY_TO_GET.toPoint)}
          isBlack={checkoutData.wayToGet === WAY_TO_GET.toPoint}
          isWhite={checkoutData.wayToGet !== WAY_TO_GET.toPoint}
          className={cn(
            styles.buttons__pickup,
            { [styles["buttons--active"]]: checkoutData.wayToGet === WAY_TO_GET.toPoint }
          )}
        />
        <CustomButton
          title={WAY_TO_GET.toDoor}
          onClick={() => selectWayToGet(WAY_TO_GET.toDoor)}
          isBlack={checkoutData.wayToGet === WAY_TO_GET.toDoor}
          isWhite={checkoutData.wayToGet !== WAY_TO_GET.toDoor}
          className={cn(
            styles.buttons__delivery,
            { [styles["buttons--active"]]: checkoutData.wayToGet === WAY_TO_GET.toDoor }
          )}
        />
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>Куда доставить заказ?</h2>
        <h2 className={styles.subtitle}>Ваш адрес</h2>
        <div ref={autocompleteListRef} className={styles.autocomplete}>
          <input
            type="text"
            name="adress"
            placeholder={isPlaceholderVisible ? "Ваш адрес" : ""}
            autoComplete="off"
            value={searchValue}
            onChange={handleChange}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            className={styles.autocomplete__input}
          />
          {showAutocompleteList && deliveryAddresses?.length > 0 && (
            <div className={styles["autocomplete__list-wrapper"]}>
              <div className={styles.autocomplete__list}>
                {deliveryAddresses.map((item, idx) => {
                  return (
                    <button key={idx} onClick={() => selectAddress(item)}>
                      {item}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={styles["btn-wrapper"]}>
          <button
            onClick={setDeliveryAddressByPosition}
            className={styles["additional-button"]}
          >
            Определить моё местоположение
          </button>
        </div>
        {checkoutData.recipient?.accountDeliveryAddresses?.length > 0 ? (
          <div className={styles["saved-addresses"]}>
            <h2 className={styles["saved-addresses__subtitle"]}>Сохраненные адреса</h2>
            <CustomScrollbar
              childrenClassName={styles["saved-addresses__list"]}
              isShowScrollbar={checkoutData.recipient?.accountDeliveryAddresses?.length > 3}
            >
              <div className={styles["saved-addresses__list"]}>
                {checkoutData.recipient?.accountDeliveryAddresses?.map((address, idx) => (
                  <button
                    key={idx}
                    onClick={() => selectSafeAddress(formatAddress(address), idx, address.city)}
                    className={cn(
                      styles["saved-addresses__button"],
                      { [styles["saved-addresses__button--selected"]]: selectedAddressIdx === idx }
                    )}
                  >
                    <div className={styles["saved-addresses__name"]}>{address.name}</div>
                    <div className={styles["saved-addresses__details"]}>
                      {`${address.city}, ул. ${address.street}, д. ${address.house}${address.flat ? `, кв. ${address.flat}` : ""}${address.entrance ? `, подъезд ${address.entrance}` : ""}${address.floor ? `, этаж ${address.floor}` : ""}`}
                    </div>
                  </button>
                ))}
              </div>
            </CustomScrollbar>
          </div>
        ) : (
          <p className={styles["saved-addresses__subtitle"]}>Сохранённых адресов нет</p>
        )}
      </div>
      <div className={styles["provider-wrapper"]}>
        <h2 className={styles.title}>Кто доставит?</h2>
        <ProvidersTitlesList
          list={props.currentProviders}
          providersInfo={props.providersInfo}
          onSelectProvider={props.onSelectProvider}
          chosenProvider={props.chosenProvider}
        />
      </div>
      <CustomButton
        title="Подтвердить выбор"
        onClick={acceptAddress}
        className={styles["select-button"]}
      />
    </div>
  );
};

const propTypes = {
  currentProviders: PropTypes.array,
  providersInfo: PropTypes.object,
  isMapVisible: PropTypes.bool,
  onSelectProvider: PropTypes.func,
  onCloseModal: PropTypes.func,
  chosenProvider: PropTypes.object,
  setCity: PropTypes.func,
};

SelectAddressForOrder.propTypes = propTypes;

export default SelectAddressForOrder;
