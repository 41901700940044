/* eslint-disable react/prop-types */
import { useMemo } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import { ICheckoutOrderItem } from "types/ICheckoutOrderItem";
import { formatPrice } from "utils/helpers/formatedPrice";

interface CheckoutOrderItemProps {
  item: ICheckoutOrderItem;
  rootStyles?: string;
  imageStyles?: string;
}

const CheckoutOrderItem: React.FC<CheckoutOrderItemProps> = ({ item, rootStyles, imageStyles }) => {

  const minPrice = useMemo(() => {
    if (!item.calculated_price) {
      const salePrice = item.offer.price_sale || item.offer.akcia;
      return salePrice || item.offer.price;
    }
    return item.calculated_price;
  }, [item]);

  const colours = item.offer.offerColours || [];
  const coloursString = colours.length > 0
    ? colours.map(c => c?.value).filter(Boolean).join(", ")
    : "";

  const volumes = item.offer.offerVolumes || [];
  const volumeString = volumes.length > 0
    ? `${volumes[0]?.value || ""} ${volumes[0]?.value_type || ""}`.trim()
    : "";

  return (
    <div
      className={cn(styles.root, rootStyles)}
      data-offer_id={item.offer.id}
      data-group_id={item.offer.catalog.id}
    >
      <img
        src={item.offer.catalog.image?.link}
        alt={item.offer.catalog.name}
        className={cn(styles.image, imageStyles)}
      />
      <div className={styles["product-data"]}>
        <p className={styles["product-data__name"]}>
          {item.offer.catalog.name}
          {volumeString && `, ${volumeString}`}
          {coloursString && `, ${coloursString}`}
        </p>
        <p className={styles["product-data__code"]}>
          {`Артикул: ${item.offer.catalog.vendorCode}`}
        </p>
      </div>
      <div className={styles["price-wrapper"]}>
        {
          item.offer.price === minPrice
            ? <p className={styles["sale-price"]}>
              {`${formatPrice(item.calculated_amount)} ₽`}
            </p>
            : <>
              <p className={styles["main-price"]}>
                {`${formatPrice(item.offer.price * item.count)} ₽`}
              </p>
              <p className={styles["sale-price"]}>
                {`${formatPrice(minPrice * item.count)} ₽`}
              </p>
            </>
        }
        <p className={styles.amount}>
          {`x ${item.count}`}
        </p>
      </div>
    </div>
  );
};

export default CheckoutOrderItem;