import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import BankCardPayment from "./Components/BankCardPayment";
import LinkToDelivery from "./Components/LinkToDelivery/LinkToDelivery";
import CashPayment from "./Components/CashPayment";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";

const PaymentPage = () => {

  const paymentData = pageInfo.payment;

  return (
    <InfoPage metaData={paymentData}>
      <h1 className={styles.seo}>Инфо страница Оплата</h1>
      <SectionTitle title="Оплата" containerStyles={styles["custom-title"]}/>
      <div className={styles.root}>
        <CashPayment />
        <BankCardPayment />
        <LinkToDelivery />
      </div>
    </InfoPage>
  );
};

export default PaymentPage;