import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

import getUserTokensFromRedirectQuery from "../../utils/getUserTokensFromRedirectQuery";
import { setTokens } from "../../utils/localStorage";

const NoIndexPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.pathname.includes("/login/vendor")) {
      const { token, refreshToken } = getUserTokensFromRedirectQuery(location.search);
      setTokens({
        token,
        refreshToken: refreshToken,
      });
      navigate("/", { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default NoIndexPage;